import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {reportMasters, navLinks} from "../../services/calendarEmployee";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import {dataTableGroupValueStrategy, fromToDates} from "../../services/common";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";


const columnData = [
    { id: 'masterName', label: 'Мастер', groupStrategy: dataTableGroupValueStrategy.concat('masterName') },
    { id: 'dayRaw', label: 'День', groupStrategy: dataTableGroupValueStrategy.fromTo('dayRaw'), parentProcessValue: fromToDates('DD.MM.YYYY'), dateFormat: 'DD.MM.YYYY',},
    { id: 'businessUnit', label: 'Салон', groupStrategy: dataTableGroupValueStrategy.concat('businessUnit')},
    { id: 'durationHours', label: 'График, ч', groupStrategy: dataTableGroupValueStrategy.concat('durationHours') },
    { id: 'durationVisitsHours', label: 'Оказано услуг, ч', groupStrategy: dataTableGroupValueStrategy.sum('durationVisitsHours') },
    { id: 'hoursCountForMaster', label: 'Загрузка мастера, ч', groupStrategy: dataTableGroupValueStrategy.sum('hoursCountForMaster') },
    { id: 'durationRqVisitsHours', label: 'Из них RQ, ч', groupStrategy: dataTableGroupValueStrategy.sum('durationRqVisitsHours') },
];
const columnDataXlsx = [
    { id: 'masterName', label: 'Мастер' },
    { id: 'day', label: 'День'},
    { id: 'businessUnit', label: 'Салон'},
    { id: 'durationHours', label: 'График, ч' },
    { id: 'durationVisitsHours', label: 'Оказано услуг, ч' },
    { id: 'hoursCountForMaster', label: 'Загрузка мастера, ч' },
    { id: 'durationRqVisitsHours', label: 'Из них RQ, ч' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ReportMastersPage extends React.Component {

    groupColumns = [
        {
            value: 'businessUnit',
            label: this.props.t("Parlour")
        },
        {
            value: 'masterName',
            label: this.props.t("Master")
        }
    ]

    state = {
        search: {
            date_start: moment(),
            date_end: moment(),
        },
        refreshTable: false,
        group: false,
        groupColumn: this.groupColumns[0].value
    };

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        if (prop === 'group' || prop === 'groupColumn' ) {
            this.setState({
                [prop]: value,
                refreshTable: !this.state.refreshTable
            });
        } else {
            this.setState({
                search: {
                    ...this.state.search,
                    [prop]: value,
                }
            });
        }
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return reportMasters(search, ...params);
    };

    handleExport = () => {
        const { search } = this.state;
        const from = search.date_start instanceof moment ? search.date_start.format('YYYY MM DD') : search.date_start;
        const to = search.date_end instanceof moment ? search.date_end.format('YYYY MM DD') : search.date_end;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Masters working");
                    XLSX.writeFile(new_workbook, `Masters working ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, group, groupColumn } = this.state;
        const { groupColumns } = this
        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.date_start}
                    valueTo={search.date_end}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('date_start')}
                    onChangeTo={this.handleChange('date_end')}
                    label={t('Show moving by date interval')}
                    className={classes.filterControl}
                />
                <CheckboxField
                    label={t('Group by')}
                    value={group}
                    onChange={this.handleChange('group')}
                    fullWidth
                />
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                        value={groupColumn}
                        onChange={this.handleChange('groupColumn')}
                        row
                    >
                        {groupColumns.map((column, i) =>
                            <FormControlLabel key={i} disabled={!group} control={<Radio/>} value={column.value} label={column.label} />
                        )}
                    </RadioGroup>
                </FormControl>
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title={t('Report on masters')}>
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    disableSorting
                    groupBy={group ? groupColumn : null}
                />
            </Content>
        </React.Fragment>;
    }
}
