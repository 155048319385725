import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import moment from "moment";
import {
    Checkbox, Fab,
    FormControlLabel,
    Grid,
    MenuItem,
    withStyles,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent, Typography, CircularProgress, Menu
} from "@material-ui/core"
import React from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
    updateVisit,
    extendVisit,
    removeVisit,
    confirmVisit,
    getVisit,
    visitStatuses,
    provideVisit,
    completeVisit,
    closeVisit,
    getVisitStatuses,
    createVisit,
    calculateVisit,
    getVisitStatusText,
    removeCreatedVisit,
    patchVisit,
    VISIT_DT_FORMAT,
    continueVisit,
    getGuestPastVisitCount,
    extendVisitForGuestWithUnclosedVisits,
    checkUnclosedVisits,
    sendVisitInformationToGuest,
    getDiscountsForVisit,
    getVisitChannels,
    notifySmsGuestCloseVisit,
    checkCodeForClosingVisit, cancelCodeForClosingVisit, closeVisitWithoutCode, getGuestsLastEntry, getContinueVisits
} from "../../services/calendar";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import Content from "../../components/content/Content";
import AnyField from "../../components/fields/AnyField";
import DateField from "../../components/fields/DateField";
import Add from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import {
    getAdminsWorking,
    getBusinessUnitRooms,
    getEquipments,
    getExternalSubjects,
    getMasters, getParlour, getReadOnlyForGuestField
} from "../../services/organization";
import {getFacilitiesVisit} from "../../services/facilities";
import {
    cancelGoodStorageSale,
    getExpressDeliveryGoodPrices, getGood,
    getGoodPrices,
    getGoodStorages, goodPurposes,
    goodStorageStatuses
} from "../../services/goods";
import GuestField from "../../components/fields/GuestField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import Pay from "../../components/pay/Pay";
import {getMastersName, getVisitDurationMinutes, getVisitTimeString} from "./helpers";
import GoodStorageField from "../../components/fields/GoodStorageField";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import _ from "lodash";
import DateTimeField from "../../components/fields/DateTimeField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField"
import {withRouter} from "react-router";
import { emptyMoney, formatMoney} from "../../services/common";
import {withTranslation} from "react-i18next";
import MedicalCardDialog from "./MedicalCardDialog";
import {Link} from "react-router-dom";
import CheckboxField from "../../components/fields/CheckboxField";
import {getUsers, hasRole, isAuthUserBusinessRoleIdInArray} from "../../services/user";
import ReferralPartnerField from "../../components/fields/ReferralPartnerField";
import NumberFormatDecimal3 from "../../components/fields/NumberFormatDecimal3";
import {paymentStatus, salePayTypes} from "../../services/sale";
import OrderTimer from "./OrderTimer";
import InputAdornment from "@material-ui/core/InputAdornment";
import FieldLabel from "../../components/fields/FieldLabel";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import VisitCancelDialog from "./VisitCancelDialog";
import {
    getGuest,
    getGuestByBitrixId,
    getGuestsInDayWithPersonalDiscounts,
    getGuestStats,
    verifyGuestName
} from "../../services/guests";
import MoneyField from "../../components/fields/MoneyField";
import SummaryVisitDataDialog from "../../components/dialogs/SummaryVisitDataDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import ScheduleVisits from "./ScheduleVisits";
import {formatDurationHour} from "../../components/fields/FacilityDurationField";
import {kkmService} from "../../services/kkm";
import ReceiptIcon from "@material-ui/icons/Receipt"
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import {getVisitReceiptData} from "../../services/moneyFlow";
import {extractBitrixDataFromQuery} from "../../services/payDocs";
import GuestNameVerificationDialog from "../../components/dialogs/GuestNameVerificationDialog";
import {
    getGeneralProperty
} from "../../services/settings";
import socketActions from "../../socket/socket-actions";
import getStore from "../../store";
import {emptyGuestStats} from "../../entities/guest";
import VerifyClosingVisitBySmsDialog from "./VerifyClosingVisitBySmsDialog";
import ViewOrderSectionDiscount, { DISCOUNT_TYPE } from "./viewOrderSections/discounts";

const styles = theme => ({
    dateVisit: {
        marginTop:  theme.spacing(2),
    },
    dateVisitHour: {
        marginTop:  theme.spacing(2),
    },
    dateField: {
        width: '100%',
        display: 'inline-flex',
    },
    facilityBlock: {
        marginTop: 10,
        marginBottom: 10,
        position: 'relative',
        border: `solid 1px ${theme.palette.grey.A500}`,
        background: '#fff',
        boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.6)',
    },
    facilityItem: {
        alignItems: 'flex-end',
    },
    subtitleVisit: {
        marginTop:  theme.spacing(1),
    },
    buttonDelFacility: {
        width: 28,
        height: 28,
        minHeight: 28,
        padding: 3,
        margin: '3px 0 3px',
    },
    buttonDelFacility_additional: {
        bottom: 13,
        width: 28,
        height: 28,
        minHeight: 28,
        padding: 3,
    },
    delButtonIcon: {
        fontSize: 14,
    },
    controlButton: {
        right: 0,
        top: 0,
        width: 46,
        position: 'absolute',
    },
    goodDel: {
        marginTop: 30,
        width: 35,
        height: 35,
        marginLeft: 5,
    },
    dateVisitChild: {
        paddingBottom: 20,
    },
    section: {
        marginTop: 10,
        marginBottom: 10,
    },
    field400: {
        width: 400,
    },
    masterField: {
        minWidth: 200,
    },
    buttonDelMaster: {
        height: 35,
        width: 35,
        minHeight:35,
        marginLeft:  theme.spacing(1),
    },
    masterRq: {
        marginLeft:  theme.spacing(0.5),
    },
    referralPartner: {
        width: 300,
    },
});

const columnData = [
    { id: 'good.name', label: 'Name of product' },
    { id: 'good.category.name', label: 'Product group' },
    { id: 'priceFormat', label: 'The price of the product' },
    { id: 'num', label: 'Item number' },
    { id: 'expiration', label: 'Shelf life', dateFormat: "DD.MM.YYYY" },
    { id: 'statusText', label: 'Status' },
];

const unclosedVisitsCheckDefaultState = {
    on_date: null,
    on_time: null
}

export default
@withRouter
@connect(state => ({
    user: state.auth.user,
    socketMessages: state.socket.messages,
}),{
    removeMessages: socketActions.removeMessages,
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ViewOrder extends React.Component {
    TIME1 = 14;
    TIME2 = 18;

    timer = null;

    state = {
        visit: {
            id: null,
            start: moment(),
            guest: null,
            parlour: null,
            files: [],
            visitFacility: [{
                id: null,
                start: moment(),
                master: [{}],
                facility: [],
                duration: '',
                price: '',
                priceId: null,
                facilityGoods: [],
                render: true,
                visitFacilityGroup: null,
                equipmentId: null,
            }],
            goodStorageSale: {
                storageGoods: []
            },
            created: moment(),
            fillingEnd: moment().add(2,'minutes'),
            fillingDuration: 120,
            saleId: null,
            price: {...emptyMoney},
            visitFacilityGroup: {
                lastId: 0,
                timeEnd: []
            },
            sale: {
                id: null,
                total: {...emptyMoney},
                totalFormatted: null
            },
            comment: null,
            resellerId: null,
            resellerComment: null,
            referralPartnerId: null,
            discountId: null,
            discountNumber: null,
            cancelReason: null,
            cancelReasonText: null,
            cancelComment: null,
            multiDiscount: [],
            gratuityPrice: null,
            gratuityTotal: null,
            gratuityForAdminFormatted: null,
            gratuityForMastersFormatted: null,
            roomId: null,
            fullVisitPrice: null,
            notifyGuestBySms: true,
            bitrixId: null,
            bitrixUserId: null,
            guestRating: null,
            guestRatingOriginalMessage: null,
            utmSource: null,
            discountType: null,
            overTimeWork: false,
            visitChannelId: null,
            visitChannel: {
                id: null,
                name: null,
            },
        },
        dialogVisitChildOpen: false,
        editing: false,
        masters: [],
        guest: {
            isClubMember: false
        },
        facilities: {},
        facilitiesSelectedPrice: null,
        goodUsingPrices: [],
        discounts: [],
        confirmDialogOpen: false,
        childStart: null,
        newStorageGoodId: null,
        searchGood: false,
        priceLoading: false,
        openMedicalCardDialog: false,
        startVisitWithMedicalCardDialog: false,
        resellers: [],
        showResellerSelect: false,
        refreshSale: false,
        equipments: [],
        openCancelDialog: false,
        guestsInDayWithDiscount: [],
        newVisitStatus: null,
        openSummaryVisitDialog: false,
        canUpdateVisitFacility: true,
        expressDeliveryGoods: [],
        visitChannels: [],
        employeeBitrixId: null,
        availableCreators: [],
        newCreator: null,
        slotAnchorEl: null,
        selectedMoment: null,
        selectedMaster: null,
        rooms: [],
        guestPastVisitsCount: null,
        kkmActive: false,
        progressOpen: false,
        progressMessage: null,
        canPrintReceipt: false,
        isBitrix24Data: false,
        bitrixRequest: {
            loading: false,
            result: null
        },
        isGuestHaveUnclosedVisitOnDay: null,
        nameVerifyingData: {
            dialogOpen: false,
            guestName: '',
            guestLastName: '',
            gender: ''
        },
        settings: {},
        unclosedVisitsCheck: unclosedVisitsCheckDefaultState,
        stats: emptyGuestStats,
        dialogOverTimeWorkingOpen: false,
        overTimeWorkedData: [],
        verifyClosingBySms: {
            isVerify: false,
            sentCode: false,
            code: null,
            id: null,
            timer: 60,
            showCodeInput: false
        },
        readOnly: true,
        newVisitRoom: null,
        lastEntry: false,
        currentParlour: null,
        continueVisits: [],
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.socketMessages !== this.props.socketMessages) {
            this.processSocketMessages(nextProps.socketMessages);
        }
    }

    componentDidMount() {
        this.init();
        getUsers().then(response => {
            if (response.success) {
                this.setState({
                    availableCreators: response.data.map(user => ({value: user.id, label: user.username})),
                });
            }
        });
        kkmService.getCurrentState().then((kkmActive) => {
            this.setState({
                kkmActive
            })
        })

        getGeneralProperty('guestNameVerificationEnable').then(response => {
            if (response.success) {
                this.setState({
                    settings: {
                        ...this.state.settings,
                        "guestNameVerificationEnable" : response.data,
                    }
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { visit } = this.state

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.init();
        }

        if (
            prevState.visit.guest !== this.state.visit.guest
            && visit.guest
            && visit.start
        )
        {
            getGuestPastVisitCount({guestId: visit.guest, date: visit.visitFacility[0].start})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guestPastVisitsCount: response.data
                        });
                    }
                });
        }
    }

    init() {
        const reId = new RegExp('/orders/(view|edit)(?:/([^/]+))?$');
        const resultId = reId.exec(this.props.location.pathname);

        if (resultId[1] && resultId[2]) {
            const editing = resultId[1] === 'edit';

            this.setState({
                editing
            });

            getVisit(resultId[2])
                .then(response => {
                    if (response.success) {
                        this.visitProcessing(response.data);
                    }
                });
        }
    }

    getSocketId() {
        return getStore().getState().auth.socket;
    }

    processSocketMessages = (messages) => {
        let processedIds = [];

        messages.forEach(message => {
            if (message.entity && message.entity === 'userAlert') {
                processedIds.push(message.id);
                if (this.getSocketId() === message.entityData.socket) {
                    this.props.showMessage(message.entityData.alert);
                }
            }
        });

        if (processedIds.length) {
            this.props.removeMessages(processedIds);
        }
    }

    getStatuses = () => {
        const {visit} = this.state;
        const { t } = this.props;

        let availableStatusesForChange = getVisitStatuses();
        if (visit.status !== visitStatuses.closed) {
            availableStatusesForChange = availableStatusesForChange.filter(status => status !== visitStatuses.closed);
        }
        availableStatusesForChange = availableStatusesForChange
            .map(status => ({id: status, name: t(getVisitStatusText(status))}));

        return availableStatusesForChange;
    }


    visitProcessing = (visit) => {
        const { t } = this.props;
        const {editing} = this.state;
        let visitFacilityGroup = {
            lastId: 0,
            timeEnd: []
        };

        //get bitrix data
        let search = this.props.location.search;
        if (search.indexOf('bitrix') !== -1) {
            let bitrixData = extractBitrixDataFromQuery(search);

            if (bitrixData.bitrixId && bitrixData.bitrixUserId) {

                this.setState({
                    isBitrix24Data: true,
                })
                visit.bitrixId = bitrixData.bitrixId;
                visit.bitrixUserId = bitrixData.bitrixUserId;

                this.setState({
                    bitrixRequest: {
                        ...this.state.bitrixRequest,
                        loading: true,
                    }
                }, () => {
                    getGuestByBitrixId(bitrixData.bitrixUserId)
                        .then((response) => {
                            if (response.success) {
                                this.setState({
                                    visit: {
                                        ...this.state.visit,
                                        guest: response.data.id
                                    },
                                    bitrixRequest: {
                                        loading: false,
                                        result: true
                                    }
                                })
                            } else {
                                this.setState({
                                    bitrixRequest: {
                                        loading: false,
                                        result: false
                                    }
                                }, () => {
                                    this.props.showInfo((t('Could not find guest with bitrix id') + ': ' + bitrixData.bitrixUserId))
                                });
                            }
                        })
                })
            }
        }

        let visitFacilityGroups = [];
        let facilitiesSelectedPrice = null;

        visit.visitFacility.forEach( (visitFacility, i) => {
            if (visit.visitFacility[i].pricePriceId && !facilitiesSelectedPrice) {
                facilitiesSelectedPrice = visit.visitFacility[i].pricePriceId;
            }
            visit.visitFacility[i].duration = visitFacility.duration / 60;
            visit.visitFacility[i].start = moment(visitFacility.start).utcOffset(visit.parlour.timeZone);
            visit.visitFacility[i].facility = visitFacility.facility && visitFacility.facility.id;

            if (visitFacilityGroups.indexOf(visitFacility.visitFacilityGroup) >= 0) {
                visit.visitFacility[i].render = false;
                visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup].add(visit.visitFacility[i].duration,'hours');
            } else if (visitFacility.visitFacilityGroup) {
                visitFacilityGroups.push(visitFacility.visitFacilityGroup);
                visit.visitFacility[i].render = true;
                visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup] = visitFacility.start.clone().add(visitFacility.duration, 'hours');
            } else {
                visit.visitFacility[i].render = true;
            }

            if (visitFacility.visitFacilityGroup && visitFacility.visitFacilityGroup > visitFacilityGroup.lastId) {
                visitFacilityGroup.lastId = visitFacility.visitFacilityGroup;
            }
        });

        let visitProcessed = {
            ...visit,
            created: moment(visit.createdAt),
            fillingEnd: moment(visit.fillingEnd),
            //price: visit.price && visit.price.val ? visit.price.val : 0,
            discountType: visit.discountId ? DISCOUNT_TYPE : null,
            guest: visit.guest && visit.guest.id,
            start: visit.visitFacility[0].start,
            visitFacilityGroup: visitFacilityGroup,
            notifyGuestBySms: true,
        };

        if (!editing && visitProcessed.status === visitStatuses.new && visitProcessed.fillingDuration > 0) {
            this.props.history.push(`/orders/edit/${visit.id}`);
        } else {
            if (!facilitiesSelectedPrice && visit.parlour && visit.parlour.prices && visit.parlour.prices[0]) {
                facilitiesSelectedPrice = visit.parlour.prices[0].id;
            }

            this.setState({
                guest: visit.guest,
                visit: visitProcessed,
                showResellerSelect: Boolean(visit.resellerId),
                facilitiesSelectedPrice,
                refreshSale: !this.state.refreshSale,
                canUpdateVisitFacility: visit.canUpdateVisitFacility || hasRole('ROLE_SCHEDULE_VISIT_SAVE_CLOSED'),
            });
            this.loadParams(visit.parlour.id, visit.guest?.id ?? null);

            getGuestsInDayWithPersonalDiscounts(visit.id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guestsInDayWithDiscount: response.data.map(guest => ({
                                id: guest.id,
                                name: `${guest.fullName} (${guest.phone})`,
                            })),
                        });
                    }
                })

            if (visit.id) {
                getReadOnlyForGuestField(visit.id).then(response => {
                    if (response.success) {
                        this.setState({
                            ...this.state,
                            readOnly: response.data.read_only
                        })
                    }
                })
                getGuestsLastEntry([visit.id]).then(response => {
                    if (response.success) {
                        this.setState(prev => ({
                            ...prev,
                            lastEntry: response.data[0]?.lastEntry ?? false
                        }));
                    }
                })
            }
        }

        if (visit?.parlour?.id) {
            getParlour(visit?.parlour?.id).then(response => {
                if (response.success) {
                    this.setState(prev => ({
                        ...prev,
                        currentParlour: response.data
                    }))
                }
            });
        }
 
        if (
            visit.status === visitStatuses.new
            && visit.parlour
            && visit.visitFacility.length
        ) {
            getAdminsWorking({
                parlour: visit.parlour.id,
                fromDate: moment(visit.visitFacility[0].start).startOf('day'),
                toDate: moment(visit.visitFacility[0].start).endOf('day')
            })
                .then(response => {
                    if (response.success && !response.data.length) {
                        this.props.showMessage(
                            t('The salon on this day works without an administrator, be sure to warn guests')
                        )
                    }
                })
        }

        if (visit.id) {
            getContinueVisits(visit.id).then(response => {
                if (response.success && response.data) {
                    this.setState(prev => ({
                        ...prev,
                        continueVisits: response.data
                    }))
                }
            })
        }
    }

    loadParams = (parlourId, guestId) => {
        getMasters({parlour: parlourId})
            .then(response => {
                if (response.success) {
                    this.setState({
                        masters: response.data,
                    });
                }
            });

        getFacilitiesVisit({parlour: parlourId, allPrices: true})
            .then(response => {
                if (response.success) {
                    const facilities = {};
                    response.data.forEach(facility => {
                        facility.prices.forEach(priceGroup => {
                            let pFacility;
                            if (facilities[priceGroup.priceId]) {
                                pFacility = facilities[priceGroup.priceId].find(item => item.id === facility.id);
                            }

                            if (!pFacility) {
                                pFacility = {
                                    ...facility,
                                    prices: [],
                                };

                                if (facilities[priceGroup.priceId]) {
                                    facilities[priceGroup.priceId].push(pFacility);
                                } else {
                                    facilities[priceGroup.priceId] = [pFacility];
                                }
                            }

                            priceGroup.durations.forEach(duration => {
                                pFacility.prices.push({
                                    ...duration,
                                    price1: duration.price1 ? duration.price1.val : null,
                                    price2: duration.price2 ? duration.price2.val : null,
                                    price3: duration.price3 ? duration.price3.val : null,
                                    priceCosmetic: duration.priceCosmetic ? duration.priceCosmetic.val : null,
                                });
                            });
                        });
                    });

                    this.setState({
                        facilities
                    });
                }
            });

        getGoodPrices({"businessUnitId": parlourId, "type": "use"})
            .then(response => {
                if (response.success) {
                    this.setState({
                        goodUsingPrices: response.data
                    });
                }
            });

        getExternalSubjects({'type': 'reseller', 'businessUnitId': parlourId})
            .then(response => {
                if (response.success) {

                    this.setState( {
                        resellers: response.data
                    });
                }
            });

        this.getDiscounts();

        getEquipments({businessUnitId: parlourId, status: 'working'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        equipments: response.data,
                    })
                }
            });

        getExpressDeliveryGoodPrices(parlourId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        expressDeliveryGoods: response.data,
                    })
                }
            });

        getBusinessUnitRooms(parlourId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        rooms: response.data,
                    })
                }
            });

        getVisitChannels()
            .then(response => {
                if (response.success) {
                    this.setState({
                        visitChannels: response.data,
                    })
                }
            })

        this.loadGuestStats(guestId);
    };

    loadGuestStats = (guestId) => {
        if (guestId === null) {
            this.setState({
                stats: emptyGuestStats,
            })
            return;
        }

        getGuestStats(guestId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        stats: response.data,
                    })
                }
            });
    };

    getDiscounts = () => {
        getDiscountsForVisit({
            visit: this.state.visit.id,
            guest: this.state.visit.guest,
            parentGuest: this.state.visit.parentGuestId,
            club: this.isClubMember() ? 1 : 0,
        }).then(response => {
            if (response.success) {
                this.setState({
                    discounts: Array.isArray(response.data) ? response.data : [response.data],

                })
            }
        });
    };

    filterDiscounts = (discount) => {
        const {visit} = this.state;

        if (discount.guest !== null) {
            return true;
        }

        if (discount.id === visit.discountId) {
            return true;
        }

        const duration = getVisitDurationMinutes(this.state.visit.visitFacility);

        if ((discount.durationFrom !== null && discount.durationFrom > duration)) {
            return false;
        }

        if ((discount.durationTo !== null && discount.durationTo < duration)) {
            return false;
        }

        return isAuthUserBusinessRoleIdInArray(discount.businessRolesIds);
    }

    calculationPrice = _.debounce(() => {

        const { visit } = this.state;

        let visitFacility = [ ...visit.visitFacility ];

        this.setState({
            priceLoading: true
        });

        calculateVisit(visit)
            .then(response => {
                if (response.success) {
                    response.data.visitFacility.forEach((item, index) => {
                        item.goods.forEach((good, index2) => {
                            if (good.price) {
                                visitFacility[index].facilityGoods[index2] = good;
                            }
                        });
                        //visitFacility[index].facilityGoods = item.goods;
                    });

                    this.setState(state => ({
                        visit: {
                            ...state.visit,
                            visitFacility: visitFacility,
                            price: response.data.total,
                            sale: {
                                ...visit.sale,
                                total: response.data.sale
                            },
                            gratuityPrice: response.data.gratuityPrice,
                            gratuityTotal: response.data.gratuityTotal,
                            gratuityForAdminFormatted: response.data.gratuityForAdminFormatted,
                            gratuityForMastersFormatted: response.data.gratuityForMastersFormatted,
                        },
                        priceLoading: false
                    }));
                } else {
                    this.setState({
                        priceLoading: false
                    });
                    this.props.showError(response.error ? response.error.message : response.message)
                }
            });
    }, 3000);

    calculateTimeStart(visitFacility, idFacility) {
        const { visit } = this.state;
        let start = visit.start.clone();
        let calculateFacility;

        calculateFacility = idFacility === undefined ? visitFacility.length - 1 : idFacility - 1;

        if (calculateFacility >= 0 && moment.isMoment(visitFacility[calculateFacility].start)) {
            start = visitFacility[calculateFacility]?.start.clone();

            if (visitFacility[calculateFacility].facility && visitFacility[calculateFacility].duration) {
                start = start.add(visitFacility[calculateFacility].duration, "hours");
            }
        }

        return start;
    }

    calculateTimeEndGroup(processedVisit) {
        let visitFacilityGroups = [];
        let { visitFacilityGroup } = this.state.visit ;

        if (!processedVisit) {
            let { visit } = this.state;
            processedVisit = { ...visit };
        }

        visitFacilityGroup.timeEnd = [];

        processedVisit.visitFacility.forEach( (visitFacility, i) => {
            if (visitFacilityGroups.indexOf(visitFacility.visitFacilityGroup) >= 0) {
                if (visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup]) {
                    visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup].add(processedVisit.visitFacility[i].duration,'hours');
                }
            } else if (visitFacility.visitFacilityGroup) {
                visitFacilityGroups.push(visitFacility.visitFacilityGroup);
                if (visitFacility?.start) {
                    visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup] = visitFacility?.start.clone().add(visitFacility.duration, 'hours');
                }
            }
        });

        return visitFacilityGroup;
    }

    validateOftenMaster = () => {
        const { visit, stats } = this.state;
        const { showMessage } = this.props;

        const masters = _.flatten(_.map(visit.visitFacility, 'master'));

        if (stats.master.id === null || (stats.master.weight ?? 0) < 3) {
            return;
        }

        const master = masters.find(master => master.id === stats.master.id);

        if (!!master && !master.requested) {
            showMessage(`Гость - постоянник мастера ${stats.master.name}. В визите требуется поставить RQ`);
        }
    }

    handleCancelSave = () => {
        const { visit } = this.state;

        if (visit.status === visitStatuses.new) {
            removeVisit(visit.id)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/orders`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        } else {
            this.props.history.push(`/orders`);
        }
    }

    handleDateChange = idVisitFacility => date => {
        const { visit: oldVisit } = this.state;
        const visitFacility = [...this.state.visit.visitFacility];

        const dateAtParlourTimeZone = date?.clone();
        if (date && oldVisit.parlour.timeZone) {
            dateAtParlourTimeZone.utcOffset(oldVisit.parlour.timeZone, true);
        }

        visitFacility[idVisitFacility] = {
            ...visitFacility[idVisitFacility],
            start: dateAtParlourTimeZone,
        };

        visitFacility.forEach((facility, i) =>{
            if (i > idVisitFacility) {
                visitFacility[i].start = this.calculateTimeStart(visitFacility, i);
            }
        });

        const visit = {
            ...oldVisit,
            visitFacility: visitFacility,
        };

        const frozenPayment = visit?.sale?.payments?.find(payment => {
            return payment.type === 'paydoc' && payment.status === 'frozen'
        });

        if (
            frozenPayment &&
            visitFacility[idVisitFacility].start.isAfter(moment(frozenPayment.payDocValidity).add(1, 'days'))
        ) {
            const formatDate = moment(frozenPayment.payDocValidity).format("DD.MM.YYYY");
            this.props.showMessage('Обратите внимание! Платежный документ действует до '+formatDate+' и будет просрочен на дату визита. Требуется продление.');
        }

        this.setState({
            visit: {
                ...visit,
                visitFacilityGroup: this.calculateTimeEndGroup(visit)
            }
        }, () => {
            this.discountReset();
            this.fetchUnclosedVisitsCheck();
        });
    };

    handleDateChildChange = data => {
        this.setState({
            childStart: data

        })
    };

    handleFacilityChange = (idVisitFacility) => event => {
        const { facilities, facilitiesSelectedPrice } = this.state;
        const { visitFacility } = this.state.visit;
        let idFacilitySelected = event.target.value;

        let facility = null;
        if (facilities[facilitiesSelectedPrice]) {
            facility = facilities[facilitiesSelectedPrice].find(item => item.id === idFacilitySelected);
        }

        if (visitFacility[idVisitFacility].facility) {
            let prevFacility = this.getFacilityById(visitFacility[idVisitFacility].facility)
            if (prevFacility) {
                this.removeGoodStoragesByGoods(prevFacility.goods)
            }
        }
        visitFacility[idVisitFacility] = {
            ...visitFacility[idVisitFacility],
            facility: idFacilitySelected,
            price: null,
            priceId: null,
            duration: null,
            equipmentId: visitFacility[idVisitFacility].equipmentId,
        };

        if (facility && facility.mastersCount !== visitFacility[idVisitFacility].master.length) {
            if (facility.mastersCount < visitFacility[idVisitFacility].master.length) {
                const masters = [...visitFacility[idVisitFacility].master];
                masters.pop();
                if (visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacility.forEach((item, id) => {
                        if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                            visitFacility[id].master = masters;
                        }
                    })
                } else {
                    visitFacility[idVisitFacility].master = masters;
                }
            } else {
                const masters = [...visitFacility[idVisitFacility].master];
                while (masters.length < facility.mastersCount) {
                    masters.push({});
                }
                if (visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacility.forEach( (item, id) => {
                        if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                            visitFacility[id].master = masters;
                        }
                    })
                } else {
                    visitFacility[idVisitFacility].master = masters;
                }
            }
        }

        /*обнулим товары услуги*/
        visitFacility[idVisitFacility].facilityGoods = [];

        let visit = {
            ...this.state.visit,
            visitFacility: visitFacility
        };

        visit = {
            ...visit,
            visitFacilityGroup: this.calculateTimeEndGroup(visit),
        };

        this.setState( {
            visit: visit
        }, () => {
            this.discountReset();
        });

        if (facility && facility.goods.length > 0) {
            this.addGoodStoragesFromFacilityGoods(
                facility.goods.filter(good => good.createSale)
            )
        }
    };

    addGoodStoragesFromFacilityGoods = (facilityGoods = []) => {
        const { visit } = this.state
        const { t } = this.props

        facilityGoods.forEach(facilityGood => {
            getGoodStorages({
                parlour: visit.parlour.id,
                goodId: facilityGood.good,
                statuses: [goodStorageStatuses.in_storage],
                purpose: goodPurposes.forSale
            })
                .then(response => {
                    if (response.success) {
                        let goodStorages
                        if (visit.goodStorageSale && visit.goodStorageSale.storageGoods) {
                            goodStorages = response.data.filter(
                                gs => !visit.goodStorageSale.storageGoods.find(gs2 => gs2.id === gs.id)
                            )
                        } else {
                            goodStorages = response.data
                        }

                        if (facilityGood.saleAmount <= goodStorages.length) {
                            Array.from(Array(facilityGood.saleAmount)).forEach((n, i) => {
                                this.handleChangeStorageGood(
                                    {
                                        target: {
                                            value: goodStorages[i].id}
                                    },
                                    goodStorages[i]
                                )
                            })
                        } else {
                            getGood(facilityGood.good)
                                .then(response => {
                                    this.props.showMessage(
                                        t("There are not enough goods in the salon for the selected service")
                                        + ` - ${response.data.name} (${facilityGood.saleAmount})`
                                    )
                                })
                        }
                    }
                })
        })
    }

    getFacilityById = (idFacilitySelected) => {
        const { facilities, facilitiesSelectedPrice } = this.state

        let facility = null;
        if (facilities[facilitiesSelectedPrice]) {
            facility = facilities[facilitiesSelectedPrice].find(item => item.id === idFacilitySelected);
        }

        return facility
    }

    handleChangeEquipment = (idVisitFacility) => event => {
        const { visitFacility } = this.state.visit;
        const equipmentId = event.target.value;

        if (visitFacility[idVisitFacility].visitFacilityGroup) {
            visitFacility.forEach((item, id) => {
                if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacility[id].equipmentId = equipmentId;
                }
            })
        } else {
            visitFacility[idVisitFacility].equipmentId = equipmentId;
        }

        const visit = {
            ...this.state.visit,
            visitFacility: visitFacility
        };

        this.setState({
            visit: visit
        }, () => {
            this.discountReset();
        });
    };

    handlePriceChange = (idVisitFacility) => event => {

        const { visitFacility } = this.state.visit;
        const { facilities, visit, facilitiesSelectedPrice } = this.state;

        let facility = null;
        if (visitFacility[idVisitFacility].facility && facilitiesSelectedPrice && facilities[facilitiesSelectedPrice]) {
            facility = facilities[facilitiesSelectedPrice].find(item => item.id === visitFacility[idVisitFacility].facility);
        }
        let price = facility ? facility.prices.find(item => item.id === event.target.value) : null;
        let priceNumber = this.getPriceNumber(visitFacility[idVisitFacility]);
        let start;

        if (price) {
            if (visitFacility[idVisitFacility].visitFacilityGroup || visitFacility[idVisitFacility].visitFacilityGroup === 0) {
                visit.visitFacilityGroup.timeEnd[visitFacility[idVisitFacility].visitFacilityGroup].add(price.time, 'hours');
            }

            visitFacility[idVisitFacility] = {
                ...visitFacility[idVisitFacility],
                price: price ? price[`price${priceNumber}`] : null,
                priceId: event.target.value,
                duration: price.time,
                priceCosmetic: price ? price.priceCosmetic : null,
            };

            visitFacility.forEach((facility, i) =>{
                if (i > idVisitFacility) {
                    start = this.calculateTimeStart(visitFacility, i);
                    visitFacility[i].start = start;
                }
            })
        } else {
            visitFacility[idVisitFacility] = {
                ...visitFacility[idVisitFacility],
                priceId: event.target.value,
            }
        }


        let processedVisit = {
            ...visit,
            visitFacility: visitFacility,
        };

        processedVisit = {
            ...processedVisit,
            visitFacilityGroup: this.calculateTimeEndGroup(processedVisit),
        }

        this.setState({
            ...this.state,
            visit: processedVisit,
        }, () => {
            this.discountReset();
            this.calculationPrice();
        });
    }

    handleGoodChange = (idVisitFacility, index, property) => event => {
        const { visitFacility } = this.state.visit;

        visitFacility[idVisitFacility].facilityGoods[index][property] = event.target.value;

        this.setState({
            ...this.state,
            visit: {
                ...this.state.visit,
                visitFacility: visitFacility,
            }
        }, () => {
            this.discountReset();
            this.calculationPrice();
        });
    };

    handleChangeMaster = (idMaster, idVisitFacility) => event => {
        const { visit, masters } = this.state;
        const { visitFacility } = this.state.visit;

        const master = masters.find(item => item.id === event.target.value);

        if (visitFacility[idVisitFacility].visitFacilityGroup) {
            visitFacility.forEach((item, id) => {
                if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacility[id].master[idMaster] = {
                        ...visitFacility[id].master[idMaster],
                        ...master
                    };
                }
            })
        } else {
            visitFacility[idVisitFacility].master[idMaster] = {
                ...visitFacility[idVisitFacility].master[idMaster],
                ...master
            };
        }

        this.setState({
            visit: {
                ...visit,
                visitFacility: visitFacility,
            },
        });
    };

    handleChangeMasterRq = (idMaster, idVisitFacility) => event => {
        const { visit } = this.state;
        const { visitFacility } = this.state.visit;

        const requested = event.target.value;

        if (visitFacility[idVisitFacility].visitFacilityGroup) {
            visitFacility.forEach((item, id) => {
                if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacility[id].master[idMaster] = {
                        ...visitFacility[id].master[idMaster],
                        requested
                    };
                }
            })
        } else {
            visitFacility[idVisitFacility].master[idMaster] = {
                ...visitFacility[idVisitFacility].master[idMaster],
                requested
            };
        }

        this.setState({
            visit: {
                ...visit,
                visitFacility: visitFacility,
            },
        });
    };

    isClubMember = () => {
        const {guest, visit} = this.state

        return (guest?.isClubMember ?? false) && !!visit?.parlour?.prices[1].id;
    }

    clubMemberCheck = () =>{
        const {visit} = this.state

        let facilitiesSelectedPrice = this.isClubMember() ? visit?.parlour?.prices[1].id : visit?.parlour?.prices[0].id;

        this.handleChangeSelectedPrice(facilitiesSelectedPrice);
    }

    handleGuestChange = (event, guest) => {
        this.loadGuestStats(guest?.id ?? null);

        this.setState({
            ...this.state,
            guest: guest,
            visit: {
                ...this.state.visit,
                guest: event.target.value
            },
            unclosedVisitsCheck: unclosedVisitsCheckDefaultState,
        }, () => {
            this.clubMemberCheck();
            this.getDiscounts();
            this.fetchUnclosedVisitsCheck();
            this.setLastEntry();
            this.discountReset();
        })
    };

    setLastEntry = () => {
        getGuestsLastEntry([this.state.visit.id], this.state.guest?.id).then(response => {
            if (response.success) {
                this.setState(prev => ({
                    ...prev,
                    lastEntry: response.data[0]?.lastEntry
                }));
            }
        });
    }

    handleAddVisitFacility = (idVisitFacility) => () => {
        const { visit } = this.state;
        const { visitFacility } = this.state.visit;
        let start;
        let group = null;

        if (idVisitFacility || idVisitFacility === 0) {
            if (visit.visitFacility[idVisitFacility].visitFacilityGroup || visit.visitFacility[idVisitFacility].visitFacilityGroup === 0) {
                group = visit.visitFacility[idVisitFacility].visitFacilityGroup;
            } else {
                group = visit.visitFacilityGroup.lastId + 1;
                visitFacility[idVisitFacility].visitFacilityGroup = group;
                visit.visitFacilityGroup.lastId = group;
                visit.visitFacilityGroup.timeEnd[group] = visitFacility[idVisitFacility].start.clone().add(visitFacility[idVisitFacility].duration, 'hours');
            }

            if (idVisitFacility === visitFacility.length - 1) {
                start = this.calculateTimeStart(visitFacility);

                visitFacility.push({
                        start: start,
                        master: visitFacility[idVisitFacility].master,
                        facility: '',
                        duration: '',
                        facilityGoods: [],
                        visitFacilityGroup: group,
                        render: false,
                        equipmentId: visitFacility[idVisitFacility].equipmentId
                    }
                )
            } else {

                let lastKeyGroup = _.findIndex(visitFacility, (visitFacility) => visitFacility.visitFacilityGroup > group);

                let newVisitFacility = {
                    start: null,
                    master: visitFacility[idVisitFacility].master,
                    facility: '',
                    duration: '',
                    facilityGoods: [],
                    visitFacilityGroup: group,
                    render: false,
                };

                if (lastKeyGroup !== -1) {
                    visitFacility.splice( lastKeyGroup, 0, newVisitFacility );
                } else {
                    visitFacility.push(newVisitFacility);
                }

                visitFacility.forEach((item, id) => {
                    if (id > idVisitFacility) {
                        start = this.calculateTimeStart(visitFacility, id);

                        visitFacility[id] = {
                            ...visitFacility[id],
                            start: start,
                            equipmentId: visitFacility[idVisitFacility].equipmentId
                        };
                    }
                });
            }
        } else {
            start = this.calculateTimeStart(visitFacility);
            visitFacility.push({
                    start: start,
                    master: [],
                    facility: '',
                    duration: '',
                    facilityGoods: [],
                    visitFacilityGroup: group,
                    render: true,
                }
            )
        }

        this.setState( {
            ...this.state,
            visit: {
                ...visit,
                visitFacility: visitFacility,
            }
        }, () => {
            this.discountReset();
            this.calculationPrice();
        });
    };

    handleAddFacilityGoods = idVisitFacility => () => {
        const { visitFacility } = this.state.visit;
        visitFacility[idVisitFacility] = {
            ...visitFacility[idVisitFacility],
            facilityGoods: [
                ...(visitFacility[idVisitFacility].facilityGoods || []),
                {
                    good: null,
                    deltaMass: null,
                    price: null
                }
            ],
        };

        this.setState({
            ...this.state,
            visit: {
                ...this.state.visit,
                visitFacility: visitFacility,
            }
        }, () => {
            this.discountReset();
            this.calculationPrice();
        });
    };

    handleSave = () => {
        const { t } = this.props;
        const { visit, nameVerifyingData,settings } = this.state;

        this.validateOftenMaster();
        updateVisit({
            ...visit,
            status: visit.status === visitStatuses.new ? visitStatuses.created : visit.status,
            visitChannelId: visit.visitChannelId ? visit.visitChannelId : null,
            employeeBitrixId: visit.creator.employeeBitrixId ? visit.creator.employeeBitrixId : null,
        })
            .then(response => {
                if (response.success) {
                    if (response.data?.message === 'overTimeWork') {
                        this.setState(prev => ({
                            ...prev,
                            overTimeWorkedData: response.data.data,
                            dialogOverTimeWorkingOpen: true,
                            visit: {
                                ...prev.visit,
                                overTimeWork: false
                            }
                        }))
                        return;
                    }

                    this.props.showInfo(t('Visit saved'));
                    this.setState({
                        visit: {
                            ...visit,
                        }
                    }, () => {
                        if (visit.guest) {
                            // ----- verifying guest name process ------
                            this.props.showInfo(t('Check guest name verifying status'));
                            getGuest(visit.guest).then((response) => {
                                if (
                                    response.success &&
                                    !_.isEmpty(settings) &&
                                    settings.guestNameVerificationEnable
                                ) {
                                    let guest = response.data;
                                    if (!guest.nameVerified) {
                                        this.setState({
                                            nameVerifyingData: {
                                                ...nameVerifyingData,
                                                dialogOpen: true,
                                                guestName: guest.name,
                                                guestLastName: guest.lastName,
                                                gender: guest.gender
                                            }
                                        });
                                    } else {
                                        this.props.history.push(`/orders/view/${visit.id}`);
                                    }
                                } else {
                                    this.props.history.push(`/orders/view/${visit.id}`);
                                }
                            });
                        } else {
                            this.props.history.push(`/orders/view/${visit.id}`);
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleExtendVisit = () => {
        const { visit } = this.state;

        extendVisit(visit.id)
            .then(this.handleExtendVisitResponse);
    };

    handleMoreDetails = () => {
        const { visit } = this.state;

        if (visit.fillingEnd > moment()) {
            extendVisitForGuestWithUnclosedVisits(visit.id)
                .then(this.handleExtendVisitResponse);
        }
    };

    handleExtendVisitResponse = response => {
        const { t } = this.props;

        if (response.success) {
            this.setState({
                visit: {
                    ...this.state.visit,
                    fillingEnd: moment(response.data.fillingEnd),
                    fillingDuration: response.data.fillingDuration,
                }
            });

            this.props.showInfo(t('Time extended'));
        } else {
            this.props.showError(response.error ? response.error.message : response.message);
        }
    }

    handleVisitFacilityDel = (idVisitFacility) => () => {
        const { visitFacility } = this.state.visit;

        let visitFacilityNew = [];
        let group = visitFacility[idVisitFacility].visitFacilityGroup;
        let elementGroup = [];

        visitFacility.forEach((item, i) => {
            if (idVisitFacility !== i) {

                /*пересчитываем время начала услуги если она после удаленной*/
                if (i > idVisitFacility) {
                    item.start = this.calculateTimeStart(visitFacilityNew);
                }

                if ((group || group === 0) && item.visitFacilityGroup === group && elementGroup.length < 2) {
                    elementGroup.push(i);
                }

                visitFacilityNew.push(item);
            } else if ((group || group === 0) && (i < visitFacility.length - 1) ) {
                visitFacility[i + 1].render = true;
            }
        });

        /* убираем значение группы в случае если в группе остался последний элемент */
        if (elementGroup.length < 2) {
            visitFacilityNew.forEach((item, i) => {
                if (item.visitFacilityGroup === visitFacility[idVisitFacility].visitFacilityGroup) {
                    visitFacilityNew[i].visitFacilityGroup = null;
                }
            })
        }

        let visit = {
            ...this.state.visit,
            visitFacility: visitFacilityNew,
        };

        visit = {
            ...visit,
            visitFacilityGroup: this.calculateTimeEndGroup(visit),
        };

        this.setState({
            visit: visit,
        }, () => {
            this.discountReset();
            this.calculationPrice();
        });

        let facility = this.getFacilityById(visitFacility[idVisitFacility].facility)

        if (facility) {
            this.removeGoodStoragesByGoods(facility.goods)
        }

        if (facility.needEquipmentType) {
            this.removeAllEquipments();
        }
    };

    handleVisitEquipmentDel = (idVisitFacility) => () => {
        const { visitFacility } = this.state.visit;
            while (visitFacility[idVisitFacility]) {
                visitFacility[idVisitFacility].equipmentId = null;
                idVisitFacility++;
            }

        const visit = {
            ...this.state.visit,
            visitFacility: visitFacility
        };

        this.setState( {
            visit: visit
        })
    }

    removeGoodStoragesByGoods = (goods) => {
        const { visit } = this.state

        if (visit.goodStorageSale
            && visit.goodStorageSale.storageGoods
            && visit.goodStorageSale.storageGoods.length
        ) {
            let goodStorages = visit.goodStorageSale.storageGoods
            goods.forEach(good => {
                if (good.createSale && good.saleAmount > 0) {
                    Array.from(Array(good.saleAmount)).forEach(n => {
                        let storageGood = visit.goodStorageSale.storageGoods.find(
                            (storageGood) => storageGood.good = good.id
                        )
                        let idx = visit.goodStorageSale.storageGoods.findIndex(
                            (storageGood) => storageGood.good = good.id
                        )
                        if (storageGood && idx > -1) {
                            _.remove(goodStorages, (value, index) => index === idx);
                        }
                    })
                }
            })
        }
    }

    removeAllEquipments = () => {
        const { visitFacility } = this.state.visit;

        visitFacility.forEach((item) =>{
            item.equipmentId = null;
            this.setState({
                visitFacility: item
            })
        })
    }

    handleUpAction = (idVisitFacility) => () => {
        const { visitFacility } = this.state.visit;
        let prevFacility = {};

        if (idVisitFacility > 0) {
            let transportedGroup;
            let elementGroupCount = 1;

            if (visitFacility[idVisitFacility].visitFacilityGroup || visitFacility[idVisitFacility].visitFacilityGroup === 0) {
                transportedGroup = visitFacility[idVisitFacility].visitFacilityGroup;
            }

            if (transportedGroup || transportedGroup === 0) {

                while (idVisitFacility + elementGroupCount < visitFacility.length && visitFacility[idVisitFacility + elementGroupCount].visitFacilityGroup === transportedGroup) {
                    elementGroupCount++;
                }
            }

            let movingGroup = visitFacility[idVisitFacility - 1].visitFacilityGroup;

            do {
                let lastId = idVisitFacility + elementGroupCount-1;

                for (let i = idVisitFacility; i <= lastId; i++) {
                    prevFacility = {...visitFacility[i - 1]};
                    visitFacility[i - 1] = {
                        ...visitFacility[i],
                        start: prevFacility.start,
                    };
                    visitFacility[i] = {
                        ...prevFacility,
                        start: this.calculateTimeStart(visitFacility, i),
                    }
                }

                if (idVisitFacility > 1 && visitFacility[idVisitFacility - 2].visitFacilityGroup === movingGroup) {
                    idVisitFacility--;
                } else {
                    break;
                }

            } while (idVisitFacility > 0);

            const visit = {
                ...this.state.visit,
                visitFacility: visitFacility,
            };

            this.setState({
                visit: {
                    ...visit,
                    visitFacilityGroup: this.calculateTimeEndGroup(visit)
                }
            }, () => {
                this.discountReset();
                this.calculationPrice();
            });
        }
    };

    handleDownAction = (idVisitFacility) => () => {
        const { visitFacility } = this.state.visit;
        let itemFacility = {};

        if (idVisitFacility < visitFacility.length-1) {

            let transportedGroup;
            let elementGroupCount = 1;

            if (visitFacility[idVisitFacility].visitFacilityGroup || visitFacility[idVisitFacility].visitFacilityGroup === 0) {
                transportedGroup = visitFacility[idVisitFacility].visitFacilityGroup;
            }

            if (transportedGroup || transportedGroup === 0) {

                while (idVisitFacility + elementGroupCount < visitFacility.length && visitFacility[idVisitFacility + elementGroupCount].visitFacilityGroup === transportedGroup) {
                    elementGroupCount++;
                }
            }

            if (idVisitFacility + elementGroupCount >= visitFacility.length) {
                return;
            }

            let movingGroup = visitFacility[idVisitFacility + elementGroupCount].visitFacilityGroup;

            do {
                let itemId = idVisitFacility + elementGroupCount-1;

                for (let i = itemId; i >= idVisitFacility; i--) {
                    itemFacility = {...visitFacility[i]};
                    visitFacility[i] = {
                        ...visitFacility[i + 1],
                        start: itemFacility.start,
                    };

                    visitFacility[i + 1] = {
                        ...itemFacility,
                        start: this.calculateTimeStart(visitFacility, i + 1),
                    }
                }

                if (idVisitFacility + elementGroupCount + 1 < visitFacility.length && visitFacility[idVisitFacility + elementGroupCount + 1].visitFacilityGroup === movingGroup) {
                    idVisitFacility++;
                } else {
                    break;
                }
            } while (idVisitFacility < visitFacility.length);

            let visit = {
                ...this.state.visit,
                visitFacility: visitFacility,
            };

            visit = {
                ...visit,
                visitFacilityGroup: this.calculateTimeEndGroup(visit)
            };

            this.setState({
                visit: visit,
            }, () => {
                this.discountReset();
                this.calculationPrice();
            });
        }
    };

    handleDiscountDelete = () => {
        let { visit } = this.state;

        (visit.multiDiscount ?? []).forEach((row, index) => {
            this.handleDeleteMultiDiscountRow(index);
        });
        this.handleDiscountChange(null);
    }

    handleDiscountChange = (type) => {
        let { visit } = this.state;

        this.setState( {
            ...this.state,
            visit: {
                ...this.state.visit,
                discountId: null,
                discountType: visit.discountType !== type ? type : null,
            }
        }, this.calculationPrice)
    };

    discountReset = () => {
        if (this.state.visit.discountId === null && this.state.visit.discountType === null) {
            return;
        }

        this.handleDiscountDelete();

        this.props.showInfo('Изменились условия визита, скидка была сброшена. Для сохранения скидки - выберите её снова.');
    }

    handleSelectDiscount = (event) => {
        const { visit, discounts } = this.state;
        const selectedDiscount = discounts.find( (item) => event.target.value === item.id );

        if (selectedDiscount) {
            this.setState({
                visit: {
                    ...visit,
                    discountId: event.target.value,
                }
            }, () => {
                if (!discounts.find((item) => event.target.value === item.id).numberRequired || visit.discountNumber) {
                    this.calculationPrice();
                }
            })
        }
    };

    handleChangeDiscountNumber = (event) => {
        const { visit, discounts } = this.state;

        this.setState({
            visit: {
                ...visit,
                discountNumber: event.target.value,
            }
        }, () => {
            if ( visit.discountId && discounts.find( (item) => visit.discountId === item.id ).numberRequired  ) {
                this.calculationPrice();
            }
        })
    };

    handleCancelVisit = () => {
        this.setState({
            openCancelDialog: true
        })
    };

    handleCloseCancelVisit = ok => {
        if (ok) {
            this.props.history.push(`/orders`);
        } else {
            this.setState({
                openCancelDialog: false
            })
        }
    };

    handleCancelUpdateVisit = () => {
        const { visit } = this.state;
        this.props.history.push(`/orders/view/${visit.id}`);
    }

    handleAddFile = () => event => {
        this.setState({
            visit: {
                ...this.state.visit,
                files: event.target.value,
            }
        })
    }

    handleEditVisit = () => () => {
        const { visit } = this.state;
        this.props.history.push(`/orders/edit/${visit.id}`);
    }

    handleFacilityGoodDel = (facilityGoodId, visitFacilityId) => () => {
        const { visitFacility } = this.state.visit;
        let newFacilityGoods = [];

        visitFacility[visitFacilityId].facilityGoods.forEach((item, i) => {
            if (facilityGoodId !== i) {
                newFacilityGoods.push(item);
            }
        })
        visitFacility[visitFacilityId].facilityGoods = newFacilityGoods;

        this.setState({
            visit:{
                ...this.state.visit,
                visitFacility: visitFacility,
            }
        }, this.calculationPrice)
    }

    handleHistoryChange = () => {
        const { visit } = this.state;
        this.props.history.push(`/orders/history-change/${visit.id}`);
    }

    handleAddChildVisit = () => {
        this.setState({
            dialogVisitChildOpen: true,
        })
    }

    handleConfirmVisit = () => {
        const { visit } = this.state;

        confirmVisit(visit.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        visit: {
                            ...visit,
                            status: visitStatuses['confirmed'],
                        }
                    })
                }
            })
    }

    handleStartVisit = () => {
        this.setState({
            openMedicalCardDialog: !this.state.openMedicalCardDialog,
            startVisitWithMedicalCardDialog: true
        });
    };

    handleProvideVisit = () => {
        const { visit } = this.state;

        provideVisit(visit.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        visit: {
                            ...visit,
                            status: visitStatuses['providing']
                        }
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleCompleteVisit = () => {
        const { visit } = this.state;

        completeVisit(visit.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        visit: {
                            ...visit,
                            status: visitStatuses['complete']
                        }
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleCloseVisit = () => {
        const { visit } = this.state;

        closeVisit(visit.id)
            .then(response => {

                if (response.success) {
                    const { isVerify } = response?.data;

                    if (!isVerify) {
                        this.setState({
                            ...this.state,
                            visit: {
                                ...visit,
                                status: visitStatuses['closed']
                            }
                        })
                    }

                    this.setState({
                        ...this.state,
                        verifyClosingBySms: response.data
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleCloseVisitWithoutCode = () => {
        const { visit } = this.state;

        closeVisitWithoutCode(visit.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        ...this.state,
                        visit: {
                            ...visit,
                            status: visitStatuses['closed']
                        }
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleActionDialog = ok => () => {
        if (ok) {

        } else {
            this.setState({
                dialogVisitChildOpen: false
            })
        }
    };


    closeDialogOverTimeWorking = () => {
        this.setState(state => ({
            ...state,
            dialogOverTimeWorkingOpen: false,
            visit: {
                ...state.visit,
                overTimeWork: false
            }

        }))
    };

    handleDialogOverTimeWorkingOpen = ok => () => {
        if (ok) {
            this.setState(state => ({
                ...state,
                visit: {
                    ...state.visit,
                    overTimeWork: true
                }
            }), () => {
                this.handleSave()
                this.closeDialogOverTimeWorking()
            })
        } else {
            this.closeDialogOverTimeWorking()
        }
    }

    handleSlotDbClick = (value, master) => {
        const { visit } = this.state;

        createVisit({
            parlour: visit.parlour,
            visitFacility: [{
                start: value,
                master: [master],
            }],
            parentId: visit.id,
            roomId: visit.roomId,
            visitChannelId: visit.visitChannelId,
        })
            .then(response => {
                if (response.success) {
                    this.setState({dialogVisitChildOpen:false});
                    this.props.history.push(`/orders/edit/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleSlotClick = (value, master) => {
        this.setState({selectedMoment: value, selectedMaster: master});
    };

    handleContextMenuOpen = (value, master, event) => {
        event.preventDefault();

        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            slotAnchorEl: event.currentTarget,
        })
    }

    handleContextMenuCreateVisit = () => {
        this.setState({
            slotAnchorEl: null,
        });

        const { selectedMoment, selectedMaster } = this.state;
        this.handleSlotDbClick(selectedMoment, selectedMaster);
    }

    handleContextMenuClose = () => {
        this.setState({
            slotAnchorEl: null,
        });
    }

    handleAddSearchGood = () => {
        this.setState({
            searchGood: true,
        })
    }

    handleExpressDeliveryGood = (price) => () => {
        const { t } = this.props;
        const { visit } = this.state;

        getGoodStorages({'goodPriceId': price.id})
            .then(response => {
                if (response.success) {
                    if (!response.data.length) {
                        this.props.showMessage(t('Product not exist already'));
                        return;
                    }
                    let goodStorage = response.data[0];
                    let storageGoods = visit.goodStorageSale && visit.goodStorageSale.storageGoods && visit.goodStorageSale.storageGoods.length ? [...visit.goodStorageSale.storageGoods] : [];
                    storageGoods.push(goodStorage);

                    this.setState({
                        visit: {
                            ...visit,
                            goodStorageSale: {
                                ...visit.goodStorageSale,
                                storageGoods: storageGoods
                            },
                        },
                    }, this.calculationPrice)

                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChangeStorageGood = (event, storageGood) => {
        const { visit } = this.state;
        let newStorageGoods;

        if (visit.goodStorageSale && visit.goodStorageSale.storageGoods) {
            newStorageGoods = visit.goodStorageSale.storageGoods.filter(item => item.id === event.target.value).length === 0 ? [...visit.goodStorageSale.storageGoods, storageGood] : [...visit.goodStorageSale.storageGoods];
        } else {
            newStorageGoods = [storageGood];
        }

        this.setState({
            visit: {
                ...visit,
                goodStorageSale: {
                    ...visit.goodStorageSale,
                    storageGoods: newStorageGoods
                },
            },
            searchGood: false,
        }, this.calculationPrice)
    };

    handleDeleteStorageGood = (storageGood, idx) => {
        const { visit } = this.state;
        let storageGoods = visit && visit.goodStorageSale && visit.goodStorageSale.storageGoods;

        if (storageGoods) {
            let isDeleteSale = storageGoods.length === 1;

            _.remove(storageGoods, (value, index) => index === idx);

            let calculateVisit = {
                ...visit,
                goodStorageSale: {
                    ...visit.goodStorageSale,
                    storageGoods: storageGoods
                },
            };

            if (storageGood.goodStorageSaleId) {
                if (isDeleteSale) {
                    cancelGoodStorageSale(storageGood.goodStorageSaleId).then(response => {
                        if (response.success) {
                            this.updateVisitAndCalculationPrice(calculateVisit)
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    })
                } else {
                    this.updateVisitAndCalculationPrice(calculateVisit)
                }
            } else {
                this.setState(state => ({
                    ...state,
                    visit: {
                        ...calculateVisit
                    }
                }), this.calculationPrice)
            }
        }
    };

    updateVisitAndCalculationPrice(visit) {
        updateVisit(visit).then(response => {
            if (response.success) {
                this.setState(state => ({
                    ...state,
                    visit: {
                        ...visit
                    }
                }), this.calculationPrice)
            }
        })
    }

    handleChange = prop => event => {
        this.setState({
            visit: {
                ...this.state.visit,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeWithCalculation = prop => event => {
        this.setState({
            visit: {
                ...this.state.visit,
                [prop]: event.target.value,
            }
        }, this.calculationPrice);
    };

    handleChangeVerifyGuestName = prop => event => {
        this.setState({
            nameVerifyingData: {
                ...this.state.nameVerifyingData,
                [prop]: event.target.value
            }
        })
    }

    toggleMedicalCardDialog = (startVisitWithMedicalCardDialog = false) => (ok) => {

        if (this.state.startVisitWithMedicalCardDialog === true && this.state.openMedicalCardDialog === true && ok) {
            this.closeMedicalCardDialogAfterStart();
            return;
        }

        this.setState({
            openMedicalCardDialog: !this.state.openMedicalCardDialog,
            startVisitWithMedicalCardDialog: startVisitWithMedicalCardDialog
        });

    };

    closeMedicalCardDialogAfterStart = () => {
        const { visit } = this.state;
        this.setState({
            visit: {
                ...visit,
                status: visitStatuses['starting']
            },
            openMedicalCardDialog: false,
            startVisitWithMedicalCardDialog: false
        })
    };

    handleRemoveVisit = () => {
        const { t } = this.props;

        this.props.showConfirm({
            message: <span>{t('Do you really want to delete a visit')}?<br/>{t('The visit will be permanently deleted')}.<br/>{t('This operation cannot be undone')}!</span>,
            title: <span>{t('Delete visit')}</span>,
            onClose: this.handleCloseRemoveVisit
        });
    };

    handleCloseRemoveVisit = ok => {
        if (ok) {
            removeCreatedVisit(this.state.visit.id)
                .then(response => {
                    if (response.success) {
                        this.props.history.push('/orders');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handlePrintReceipt = () => {
        const { t, showMessage, user } = this.props
        const { visit } = this.state
        this.setState({
            progressOpen: true,
            progressMessage: null,
        })
        getVisitReceiptData(visit?.id).then((response) => {
            const { success, data, error, message } = response

            if (!success) {
                this.setState({
                    progressOpen: false,
                })
                showMessage(error ? error.message : message);
                return
            }

            this.setState({
                progressMessage: t('Check printing in progress')
            })
            if (!Array.isArray(data))
                kkmService.registerCheck(user.username, data.cash, data.cashless, data.items).then(() => {
                    this.setState({
                        progressOpen: false,
                    })
                })
            else if (data.length > 0){
                let promises = [];
                data.forEach((oneData) => {
                    promises.push(kkmService.registerCheck(user.username, oneData.cash, oneData.cashless, oneData.items));
                });
                Promise.all(promises).then( () => {
                    this.setState({
                        progressOpen: false,
                    })
                })
            }
        })
    }

    handlePrintRefundReceipt = () => {
        const { t, showMessage, user } = this.props
        const { visit } = this.state
        this.setState({
            progressOpen: true,
            progressMessage: null,
        })
        getVisitReceiptData(visit?.id).then((response) => {
            const { success, data, error, message } = response

            if (!success) {
                this.setState({
                    progressOpen: false,
                })
                showMessage(error ? error.message : message);
                return
            }

            this.setState({
                progressMessage: t('Check printing in progress')
            })
            if (!Array.isArray(data))
                kkmService.registerCheck(user.username, data.cash, data.cashless, data.items, 4, true).then(() => {
                    this.setState({
                        progressOpen: false,
                    })
                })
            else if (data.length > 0){
                let promises = [];
                data.forEach((oneData) => {
                    promises.push(kkmService.registerCheck(user.username, oneData.cash, oneData.cashless, oneData.items, 4, true));
                });
                Promise.all(promises).then( () => {
                    this.setState({
                        progressOpen: false,
                    })
                    this.props.showInfo(t('Receipt printed'))
                })
            }
        })
    }

    handleOnChangeAddPaymentMethod = (payments) => {
        let canPrintReceipt = false;

        if ( payments && payments.length > 0 )
            _.forEach(payments, (payment) => {
                if (payment.status === 'paid') {
                    if (payment.type === "paydoc") {
                        if (
                            (payment.payDocTypeCode && payment.payDocTypeCode === 'ПО') ||
                            (!payment.payDocTypeCode && payment.payDocName.substring(0, 2) === 'ПО')
                        )
                            canPrintReceipt = true;
                    } else {
                        canPrintReceipt = true;
                    }
                }

                if (canPrintReceipt)
                    return false;
            })

        if (canPrintReceipt)
            this.setState({
                canPrintReceipt: true
            })
    }

    handleVerifyGuestName = () => {
        let { nameVerifyingData, visit } = this.state;

        let guest = {
            id: visit.guest,
            name: nameVerifyingData.guestName,
            lastName: nameVerifyingData.guestLastName,
            gender: nameVerifyingData.gender
        };

        verifyGuestName(guest).then(response => {
            if (response.success) {
                this.setState({
                    nameVerifyingData: {
                        ...nameVerifyingData,
                        dialogOpen: false,
                    }
                }, () => {
                    this.props.history.push(`/orders/view/${visit.id}`);
                })
            } else {
                this.props.history.push(`/orders/view/${visit.id}`);
            }
        })
    }

    handleSendMessageWithVisitInformation = () => {
        const { t, showError,showInfo } = this.props;

        sendVisitInformationToGuest(this.state.visit.id).then((response) => {
            if (response.success) {
                showInfo( t(`Message sent`) );
            } else {
                showError(response?.error?.message)
            }
        })
    }

    render(){
        const { classes, t } = this.props;
        const {
            visit,
            discounts,
            editing,
            dialogVisitChildOpen,
            childStart,
            newStorageGoodId,
            searchGood,
            openMedicalCardDialog,
            startVisitWithMedicalCardDialog,
            showResellerSelect,
            resellers,
            refreshSale,
            guestsInDayWithDiscount,
            expressDeliveryGoods,
            slotAnchorEl,
            selectedMoment,
            selectedMaster,
            kkmActive,
            progressOpen,
            progressMessage,
            canPrintReceipt,
            bitrixRequest,
            nameVerifyingData,
            settings,
            dialogOverTimeWorkingOpen,
            overTimeWorkedData,
            visitChannels,
            verifyClosingBySms,
        } = this.state;

        const visitEnd = visit?.start.clone();

        let facilitiesWithoutPriceId = false;
        visit.visitFacility.forEach(( facility ) => {
            if (facility.duration) {
                visitEnd.add(facility.duration, 'hours');
            }

            if (!facility.priceId) {
                facilitiesWithoutPriceId = true;
            }
        });

        const fillingNew = visit.status === visitStatuses.new && visit.fillingDuration > 0;

        let showGratuitySection = false;
        if (visit.parlour && visit.parlour.gratuityEnabled && visit.status !== visitStatuses.new) {
            showGratuitySection = true;
        }

        const hasDefaultReseller = Boolean(visit.parlour && visit.parlour.type === 'external_parlour' && visit.parlour.defaultResellerId && visit.resellerId);

        const isDisabledSave = (showResellerSelect && !visit.resellerId) || !visit.id || facilitiesWithoutPriceId || (visit.guest && visitChannels?.length && !visit.visitChannelId && !visit.parentId);

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Visit card")}/>}>
                    { this.renderLeftFields() }
                    <br/>
                    <br/>
                    <React.Fragment>
                        <ActionButton onClick={this.handleEditVisit(true)} visible={visit.status !== visitStatuses.new && !editing}>{t("Edit visit parameters")}</ActionButton>
                        <ActionButton onClick={this.handleConfirmVisit} visible={visit.parlour && visit.parlour.hasRiskyStatus && [visitStatuses.created, visitStatuses.risky].indexOf(visit.status) >= 0 && !editing}>{t("Confirm visit")}</ActionButton>
                        <ActionButton onClick={this.handleProvideVisit} visible={visit.status === visitStatuses.starting && !editing && Boolean(visit && !visit.hasWaitingMasters)}>{t("Start of service")}</ActionButton>
                        <ActionButton onClick={this.handleStartVisit} visible={[visitStatuses.created, visitStatuses.confirmed, visitStatuses.risky].indexOf(visit.status) >= 0 && !editing && Boolean(visit && !visit.hasWaitingMasters)}>{t("Confirm the arrival of the guest in the salon")}</ActionButton>
                        <ActionButton onClick={this.handleCompleteVisit} visible={visit.status === visitStatuses.providing && !editing && Boolean(visit && !visit.hasWaitingMasters)}>{t("End of service")}</ActionButton>
                        <ActionButton onClick={this.handleCloseVisit} visible={/*TODO: Временно отключено*/ [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete].indexOf(visit.status) >= 0  && !editing && Boolean(visit && !visit.hasWaitingMasters)}>{t("Close visit")}</ActionButton>
                        {
                            hasRole('ROLE_CONFIRM_CLOSING_VISIT_BY_SMS') &&
                            <ActionButton onClick={this.handleCloseVisitWithoutCode} visible={[visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete].indexOf(visit.status) >= 0  && !editing && Boolean(visit && !visit.hasWaitingMasters)}>Закрыть визит без кода</ActionButton>
                        }
                        <ActionButton onClick={this.handleExtendVisit} visible={fillingNew}>{t("Extend for 30 sec")}</ActionButton>
                        <ActionButton onClick={this.handleSave} disabled={isDisabledSave} visible={!((!fillingNew && visit.status === visitStatuses.new) || !editing)}>{ visit.status === visitStatuses.new ? t("Create a visit") : t("Save") }</ActionButton>
                        <ActionButton onClick={this.handleCancelSave} visible={visit.status === visitStatuses.new}>{t("Cancellation of the visit")}</ActionButton>
                        <ActionButton onClick={this.handleCancelUpdateVisit} visible={editing && visit.status !== visitStatuses.new}>{t("Cancel")}</ActionButton>
                        <ActionButton
                            onClick={this.handleCancelVisit}
                            visible={!editing && (
                                visit.status === visitStatuses.created ||
                                visit.status === visitStatuses.confirmed ||
                                visit.status === visitStatuses.risky ||
                                (
                                    (
                                        visit.status === visitStatuses.starting ||
                                        visit.status === visitStatuses.providing ||
                                        visit.status === visitStatuses.complete
                                    ) &&
                                    !(visit.sale && visit.sale.payments && visit.sale.payments.length)
                                    && hasRole('ROLE_SCHEDULE_VISIT_CANCEL_AFTER_STARTING')
                                )
                            )}
                        >{t("Cancel visit")}</ActionButton>
                        <ActionButton onClick={this.handleHistoryChange} visible={!editing}>{t("History of change of parameters")}</ActionButton>
                        <ActionButton onClick={this.toggleMedicalCardDialog(false)} visible={visit.id !== null && visit.guest !== null && visit.status !== visitStatuses.new}>{t("Medical record")}</ActionButton>
                        <ActionButton
                            color="primary"
                            onClick={this.handleRemoveVisit}
                            visible={visit.id && hasRole('ROLE_SCHEDULE_VISIT_REMOVE') && visit.status !== visitStatuses.closed && !(visit.sale && visit.sale.payments && visit.sale.payments.filter(payment => payment.status === paymentStatus.paid).length)}
                        >Удалить визит</ActionButton>
                        <ActionButton onClick={() => this.setState({openSummaryVisitDialog: true})} visible={visit.id && hasRole('ROLE_MONEYFLOW_SUMMARYDAYREPORT_ONE_VISIT_GET')}>Суммарная информация</ActionButton>
                        <ActionButton visible={kkmActive && canPrintReceipt} onClick={this.handlePrintReceipt}>{t('Print receipt')} <ReceiptIcon /></ActionButton>
                        <ActionButton visible={kkmActive && canPrintReceipt} onClick={this.handlePrintRefundReceipt}>{t('Print refund receipt')}</ActionButton>
                        <ActionButton
                            onClick={this.handleSendMessageWithVisitInformation}
                            visible={
                                visit.status !== visitStatuses.closed &&
                                visit.status !== visitStatuses.canceled &&
                                visit.status !== visitStatuses.complete &&
                                visit.status !== visitStatuses.new &&
                                !!visit.id
                            }
                        >
                            {t("Send whatsapp message with visit information")}
                        </ActionButton>
                    </React.Fragment>
                </LeftBar>
                <Content title={t("Visit card")} loading={!visit.id}>
                    <Grid container  spacing={2}>
                        {Array.isArray(visit.visitFacility) ? visit.visitFacility.map( (visitFacility, idVisitFacility) => {
                            return (
                                visitFacility.render
                                    ? this.renderVisitFacility(visitFacility, idVisitFacility)
                                    : null
                            )
                            })
                            : null
                        }
                        <ActionButton
                            fullWidth={false}
                            visible={editing}
                            onClick={this.handleAddVisitFacility()}
                            noMargin
                            inGridItem12
                        >
                            <Add/> {t("Add service")}
                        </ActionButton>
                        <Grid item xs={8}>
                            {visit.goodStorageSale && visit.goodStorageSale.storageGoods && Array.isArray(visit.goodStorageSale.storageGoods) && visit.goodStorageSale.storageGoods.length?
                                <DataTableLocal
                                    data={visit.goodStorageSale.storageGoods}
                                    columnData={columnData}
                                    selector={false}
                                    pagination={false}
                                    onDelete={!editing ? null : this.handleDeleteStorageGood}
                                />
                                : null
                            }
                            {searchGood ?
                                <Grid item xs={12}>
                                    <GoodStorageField
                                        label={t("Product")}
                                        value={newStorageGoodId}
                                        onChange={this.handleChangeStorageGood}
                                        additionalFilter={{parlour: visit.parlour.id}} // вот тут .id добавил потому что полный объект передавался
                                    />
                                </Grid>
                                : null
                            }
                            <ActionButton
                                fullWidth={false}
                                visible={editing}
                                onClick={this.handleAddSearchGood}
                                inGridItem12
                            >
                                <Add/>
                                {t("Add product")}
                            </ActionButton>
                        </Grid>
                        {!!expressDeliveryGoods.length &&
                        <Grid item xs={4}>
                            <Grid item xs={12} className={classes.subtitleVisit}><Typography
                                variant="subtitle1">{t("Express delivery products")}</Typography></Grid>
                            <List>
                                {expressDeliveryGoods.map(price => {
                                        let checked = !!(visit.goodStorageSale && visit.goodStorageSale.storageGoods && visit.goodStorageSale.storageGoods.length
                                            && visit.goodStorageSale.storageGoods.find((goodStorage) => goodStorage.good.id === price.good.id));
                                        return (
                                            <React.Fragment key={price.id}>
                                                <ListItem role={undefined} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked}
                                                            onChange={this.handleExpressDeliveryGood(price)}
                                                            disabled={!editing || checked}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={price.good.nameWithMass}/>
                                                    <ListItemSecondaryAction>
                                                        <ListItemText primary={price.salePriceFormatted}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider variant="fullWidth" component="li"/>
                                            </React.Fragment>
                                        )
                                    }
                                )}
                            </List>
                        </Grid>
                        }
                        <ActionButton
                            fullWidth={false}
                            visible={!editing && visit.id && visit.status !== visitStatuses.new}
                            color="primary"
                            onClick={this.handleContinueVisit}
                            inGridItem12
                        >
                            {t("Continue visit")}
                        </ActionButton>
                        <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Discounts and promotions")}</Typography></Grid>
                        <Grid item xs={12}>
                            <ViewOrderSectionDiscount
                                visit={visit}
                                editing={editing}
                                discounts={discounts}
                                guestsInDayWithDiscount={guestsInDayWithDiscount}
                                filterDiscounts={this.filterDiscounts}
                                handleDiscountChange={this.handleDiscountChange}
                                handleDiscountDelete={this.handleDiscountDelete}
                                handleSelectDiscount={this.handleSelectDiscount}
                                handleChangeDiscountNumber={this.handleChangeDiscountNumber}
                                handleAddMultiDiscountRow={this.handleAddMultiDiscountRow}
                                handleChangeMultiDiscountRow={this.handleChangeMultiDiscountRow}
                                handleDeleteMultiDiscountRow={this.handleDeleteMultiDiscountRow}
                            />
                        </Grid>
                        {showGratuitySection &&
                            <React.Fragment>
                                <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Gratuity")}</Typography></Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <MoneyField
                                                onChange={this.handleChangeWithCalculation('gratuityPrice')}
                                                currencies={visit.parlour.usingCurrencies}
                                                value={visit.gratuityPrice}
                                                readOnly={!editing}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            {visit.parlour && visit.parlour.gratuityAdminPercent !== 0 &&
                                            <>
                                                {t("Administrators will receive")}: ~{visit.gratuityForAdminFormatted}
                                                <br/>
                                            </>
                                            }
                                            {t("Masters will receive")}: ~{ visit.gratuityForMastersFormatted }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }

                        <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Payment methods")}</Typography></Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                className={classes.promotionsCheck}
                                                checked={ !!showResellerSelect }
                                                onChange={ () => this.setState({ showResellerSelect: !showResellerSelect, visit: {...visit, resellerId: null}  }) }
                                                disabled={Boolean(!editing || hasDefaultReseller) }
                                                value='1'
                                            />
                                        }
                                        label={t("Will be payed by reseller")}
                                    />
                                </Grid>
                                {showResellerSelect &&
                                    <React.Fragment>
                                        <Grid item xs={2}>
                                            <AnyField
                                                label={t("Reseller")}
                                                value={ visit.resellerId}
                                                fullWidth
                                                readOnly={!editing || hasDefaultReseller}
                                                onChange = { this.handleChange('resellerId') }
                                                select
                                            >
                                                {resellers.map((item, i) => <MenuItem key={i} value={item.id}>{item.name}</MenuItem>)}
                                            </AnyField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AnyField
                                                label={t("Reseller comment")}
                                                value={visit.resellerComment}
                                                onChange={this.handleChange('resellerComment')}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                readOnly={!editing}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>

                        {visit.saleId && !editing && (
                            (visit.parlour.type === 'parlour' && !showResellerSelect) ||
                            (visit.parlour.type === 'external_parlour' && visit.parlour.availablePaymentTypes.length)
                        ) ?
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Pay
                                        saleId={visit.saleId}
                                        guestId={visit.guest}
                                        buttonLabel={<React.Fragment><Add/>{t("Add payment method")}</React.Fragment>}
                                        amountTotal={ visit.sale.total.val }
                                        refreshSale={refreshSale}
                                        onChange={this.handleOnChangeAddPaymentMethod}
                                        disabled={Boolean(visit && visit.hasWaitingMasters)}
                                        availablePaymentTypes={
                                            visit.parlour.type === 'external_parlour' && visit.parlour.availablePaymentTypes.length ?
                                                visit.parlour.availablePaymentTypes
                                                : _.values(salePayTypes)
                                        }
                                        disablePaymentLinks={visit.parlour.type === 'external_parlour'}
                                        filterPayDocsWithValidityBefore={moment().subtract(2, 'weeks')}
                                    />
                                </Grid>
                            </React.Fragment>
                            : null
                        }
                        {hasRole('ROLE_ORGANIZATION_REFERRAL_PARTNER_GET') &&
                            <Grid item xs={12}>
                                <ReferralPartnerField
                                    className={classes.referralPartner}
                                    label={t("Referral partner")}
                                    value={visit.referralPartnerId}
                                    readOnly={!editing || !visit.id}
                                    onChange={this.handleChange('referralPartnerId')}
                                />
                            </Grid>
                        }
                        {(visit.files && visit.files.length) || editing ?
                            <React.Fragment>
                                <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Visit files")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <UploadFilesField
                                        id="technicalProject"
                                        buttonLabel={<React.Fragment><Add/>{t("Add file")}</React.Fragment>}
                                        value={visit.files}
                                        multiple
                                        disabled={!editing}
                                        limit={30}
                                        onChange={this.handleAddFile()}
                                    />
                                </Grid>
                            </React.Fragment>
                            : null
                        }
                        {visit.hasChildren || editing || this.state.continueVisits.length ?
                            <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Related visits")}</Typography></Grid>
                            : null
                        }
                        {visit.hasChildren ?
                                visit.children.map((child, idx) => <Grid item xs={12} key={idx}>
                                    <Link to={'/orders/view/' + child.id}>{child.visitFacility.map(facility => moment(facility.start).utcOffset(child.parlour.timeZone).format("DD.MM.YYYY HH:mm") + ' ' + facility.master.map(m => m.name).join(',')).join('; ') }</Link>
                                </Grid>)
                                : null
                        }
                        {
                            this.state.continueVisits.length > 0 &&
                            this.state.continueVisits.map((v, idx) => <Grid item xs={12} key={idx}>
                                <Link to={'/orders/view/' + v.id}>{v.visitFacility.map(facility => moment(facility.start).utcOffset(v.parlour.timeZone).format("DD.MM.YYYY HH:mm") + ' ' + facility.master.map(m => m.name).join(',')).join('; ') + ' (продление)' }</Link>
                            </Grid>)
                        }
                        {
                            visit.continueOfId &&
                            <Grid item xs={12}><Link to={'/orders/view/' + visit.continueOfId}>{t("Parent visit")}</Link></Grid>
                        }
                        {visit.parentId ?
                            <Grid item xs={12}><Link to={'/orders/view/' + visit.parentId}>{t("Parent visit")}</Link></Grid>
                            :
                            <ActionButton
                                fullWidth={false}
                                onClick={this.handleAddChildVisit}
                                visible={visit.status !== visitStatuses.new}
                                inGridItem12
                            >
                                <Add />
                                {t("Add Attachment Visit")}
                            </ActionButton>
                        }
                        <Grid item xs={4}>
                            <AnyField
                                label={t("Comment on the visit")}
                                value={visit.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.subtitleVisit}><Typography variant="subtitle1">{t("Notifications")}</Typography></Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                className={classes.promotionsCheck}
                                                checked={!!this.state.visit.notifyGuestBySms}
                                                disabled={!editing}
                                                onChange={
                                                    () => this.setState(
                                                        {
                                                            visit: {
                                                                ...this.state.visit,
                                                                notifyGuestBySms: !this.state.visit.notifyGuestBySms
                                                            }
                                                        }
                                                    )
                                                }
                                                value='1'
                                            />
                                        }
                                        label={t("Notify guest by sms")}
                                    />
                                </Grid>
                            </Grid>
                    </Grid>
                    <ProgressDialog open={bitrixRequest.loading} message={t('Finding user by bitrix id')}/>
                </Content>
                {dialogVisitChildOpen &&
                    <Dialog open={dialogVisitChildOpen} maxWidth="lg">
                        <DialogTitle>{t("Choose a visit time")}</DialogTitle>
                        <DialogContent>
                            <DateField
                                className={classes.dateVisitChild}
                                value={childStart || visit.start}
                                label={t("Date of visit")}
                                onChange={this.handleDateChildChange}
                            />
                            <ScheduleVisits
                                date={(childStart || visit.start).utcOffset(visit.parlour.timeZone).startOf('day')}
                                parlour={this.state.currentParlour}
                                onSlotClick={this.handleSlotClick}
                                onSlotDbClick={this.handleSlotDbClick}
                                onSlotContextMenu={this.handleContextMenuOpen}
                            />
                            <Menu
                                id="slot-popup-menu"
                                anchorEl={slotAnchorEl}
                                open={Boolean(slotAnchorEl)}
                                onClose={this.handleContextMenuClose}
                            >
                                <MenuItem onClick={this.handleContextMenuCreateVisit} dense>{t("Add visit")}</MenuItem>
                            </Menu>
                        </DialogContent>
                        <DialogActions>
                            {selectedMoment && selectedMaster ?
                                <>
                                    <div>{selectedMoment.utcOffset(visit.parlour.timeZone).format(VISIT_DT_FORMAT)} - {selectedMaster.name}</div>
                                    <ActionButton fullWidth={false} noMargin onClick={this.handleContextMenuCreateVisit}>{t("Add visit")}</ActionButton>
                                </>
                                : null
                            }
                            <Button onClick={this.handleActionDialog(false)} color="primary">{t("Cancel")}</Button>
                        </DialogActions>
                    </Dialog>
                }

                {dialogOverTimeWorkingOpen && <Dialog open={dialogOverTimeWorkingOpen}>
                    <DialogTitle> Переработка</DialogTitle>
                    <DialogContent>
                        {overTimeWorkedData.map((item) => (<Grid key={item.masterName}>
                            Максимальная загрузка мастера "{item.masterName}" : {item.maxWorkHours} часов. Уже запланировано : {item.facilitiesHours} часов
                        </Grid>))}
                    </DialogContent>
                    <DialogActions>
                        <ActionButton onClick={this.handleDialogOverTimeWorkingOpen(true)}>Подтвердить создание визита</ActionButton>
                        <Button onClick={this.handleDialogOverTimeWorkingOpen(false)}
                            color="primary">{t("Cancel")}</Button>

                    </DialogActions>
                </Dialog>
                }

                <GuestNameVerificationDialog
                    open={
                        nameVerifyingData.dialogOpen &&
                        !_.isEmpty(settings) &&
                        settings.guestNameVerificationEnable
                    }
                    nameVerifyData={nameVerifyingData}
                    onChange={this.handleChangeVerifyGuestName}
                    onSubmit={this.handleVerifyGuestName}
                />
                { openMedicalCardDialog && <MedicalCardDialog open={openMedicalCardDialog} visitId={visit.id} onClose={this.toggleMedicalCardDialog(false)} startVisit={startVisitWithMedicalCardDialog}/> }
                { this.state.openCancelDialog && <VisitCancelDialog onClose={this.handleCloseCancelVisit} open={this.state.openCancelDialog} visitId={this.state.visit.id} />}
                { this.state.openSummaryVisitDialog && <SummaryVisitDataDialog visitId={visit.id} open={this.state.openSummaryVisitDialog} onClose={() => this.setState({openSummaryVisitDialog: false})} /> }
                { verifyClosingBySms.isVerify &&
                    <VerifyClosingVisitBySmsDialog
                        data={verifyClosingBySms}
                        handleSendCode={this.handleSendCode}
                        handleChange={this.handleChangeVerifyClosingBySms}
                        handleSendAcceptCode={this.handleSendAcceptCode}
                        handleCancelCode={this.handleCancelCode}/> }
                <ProgressDialog open={progressOpen} message={progressMessage} />
            </React.Fragment>
        )
    }

    handleCancelCode = () => {
        cancelCodeForClosingVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.setState({
                    verifyClosingBySms: res.data
                });
            } else {
                this.props.showError(res.error ? res.error.message : res.message);
            }
        })
    }

    handleSendAcceptCode = () => {
        checkCodeForClosingVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.props.showInfo('Закрытие визита')

                this.handleCloseVisit();
            } else {
                this.props.showError(res.error ? res.error.message : res.message);
            }
        })
    }

    handleChangeVerifyClosingBySms = e => {
        this.setState({
            verifyClosingBySms: {
                ...this.state.verifyClosingBySms,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleSendCode = () => {
        const { t } = this.props;

        notifySmsGuestCloseVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.setState({
                    verifyClosingBySms: {
                        ...res.data,
                        timer: 60
                    }
                })

                let countdown = setInterval(() => {
                    let timer = this.state.verifyClosingBySms.timer - 1;
                    this.setState({
                        verifyClosingBySms: {
                            ...this.state.verifyClosingBySms,
                            timer: timer
                        }
                    });

                    if (timer === 0) {
                        clearInterval(countdown);
                        this.setState({
                            verifyClosingBySms: {
                                ...this.state.verifyClosingBySms,
                                sentCode: false,
                                timer: 60
                            }
                        });
                    }
                }, 1000);

                this.setState({
                    verifyClosingBySms: {
                        ...this.state.verifyClosingBySms,
                        showCodeInput: true,
                    }
                });

                this.props.showInfo(t('SMS code sent to guest'))
            } else {
                this.props.showMessage(res.error ? res.error.message : res.message);
            }
        })
    }

    handleChangeSelectedPrice = event => {
        let price = typeof event?.target?.value !== 'undefined' ? event.target.value : event;

        this.setState({
            facilitiesSelectedPrice: price
        })
    };

    handleTimerOver = () => {
        this.setState({
            visit: {
                ...this.state.visit,
                fillingDuration: 0,
            }
        })
    };

    handleChangeCreator = event => {
        const {t} = this.props;

        this.setState({
            newCreator: event.target.value,
        });

        this.props.showConfirm({
            message: t('Are you sure you want to change your visit creator'),
            title: t('Change of visit creator'),
            onClose: this.changeVisitCreator
        });
    };

    changeVisitCreator = ok => {
        if (!ok) return;

        const { visit, newCreator } = this.state;

        patchVisit(visit.id, { creator: newCreator })
            .then(response => {
                if (response.success){
                    this.visitProcessing(response.data);
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            });
    }

    renderLeftFields() {
        const { t } = this.props;
        const {
            visit,
            editing,
            priceLoading,
            facilitiesSelectedPrice,
            guest,
            availableCreators,
            rooms,
            guestPastVisitsCount,
            isBitrix24Data,
            unclosedVisitsCheck,
            visitChannels,
            readOnly
        } = this.state;

        let totalSum = formatMoney(visit.price);
        let totalSaleSum = formatMoney(visit.sale ? visit.sale.total : null);

        return (
            <React.Fragment>
                <ShowField
                    label={t("Parlour")}
                    value={`${visit?.parlour?.name ?? ''} ${visit?.parlour?.address ?? ''}`}
                />
                <AnyField
                    label={t("Price list")}
                    value={facilitiesSelectedPrice}
                    onChange={this.handleChangeSelectedPrice}
                    readOnly={!editing || !visit.parlour}
                    disabled={!hasRole("ROLE_CLUB_GUEST_EDIT")}
                    fullWidth
                    select
                    visible={Boolean(visit && visit.parlour && visit.parlour.prices)}
                >
                    {(visit && visit.parlour && visit.parlour.prices ? visit.parlour.prices : []).map(price =>
                        <MenuItem key={price.id} value={price.id}>{price.name}</MenuItem>
                    )}
                </AnyField>
                <ShowField
                    label = {t("Visit time")}
                    value = { getVisitTimeString(visit.visitFacility) }
                />
                <GuestField
                    label={t("Guest")}
                    required={!visit.parentId}
                    value={visit.guest}
                    error={!visit.guest && !visit.parentId}
                    onChange={ this.handleGuestChange }
                    showStar={ guestPastVisitsCount === 0 }
                    showPrevStar={ this.state.lastEntry }
                    readOnly={ !editing || (Boolean(readOnly) && !hasRole('ROLE_CONFIRM_CLOSING_VISIT_BY_SMS')) }
                />
                {unclosedVisitsCheck.on_date && guest &&
                    <p>
                        {t("The guest has unclosed visits for the selected day") + ". "}
                        <a href={`/guests/history-visit/${guest.id}`} onClick={this.handleMoreDetails} target={'_blank'} rel="noopener noreferrer">{t("More details")}</a>
                    </p>
                }
                <ShowField
                    label = {t("Guest phone")}
                    value = {guest && guest.phone}
                />
                <ShowField
                    label = {t("Guest Birthday")}
                    value = {guest && guest.birthDate && moment(guest.birthDate).format('DD-MM-Y')}
                />
                <ShowField
                    label={t("Past visits count")}
                    value={guestPastVisitsCount}
                />
                <ShowField
                    label={t("Special status")}
                    visible={Boolean(guest && guest.specialStatus)}
                >
                    {guest ? guest.specialStatus : null}
                </ShowField>
                <ShowField
                    label = {t("Master")}
                    value = { getMastersName(visit.visitFacility) }
                />
                <ShowField
                    label = {t("Cost of visit")}
                    value =  { totalSum }
                    endAdornment={ priceLoading ? <CircularProgress size={20}/> : null }
                />
                <ShowField
                    label = {t("Full amount of sale")}
                    value =  { totalSaleSum }
                    endAdornment={ priceLoading ? <CircularProgress size={20}/> : null }
                />
                <ShowField
                    label = {t("Full cost of visit")}
                    value =  { visit && visit.fullVisitPrice ? visit.fullVisitPrice : '' }
                    visible={!editing}
                />
                <ShowField
                    label = {t("Bitrix id")}
                    value =  { visit && visit.bitrixId ? visit.bitrixId : '' }
                    visible = {isBitrix24Data || !!visit.bitrixId}
                />
                <ShowField
                    label = {t("Bitrix user id")}
                    value =  { visit && visit.bitrixUserId ? visit.bitrixUserId : '' }
                    visible = {isBitrix24Data}
                />
                <ShowField
                    label = {t("Utm")}
                    value =  { visit && visit.utmSource ? visit.utmSource : '' }
                    visible = {visit && visit.utmSource !== null}
                />
                {
                    visit.visitChannel.name !== null
                    ?
                        <ShowField
                            label = {t("Visit channel")}
                            value = {visit && visit.visitChannel.name ? visit.visitChannel.name : null}
                        />
                    :
                        !_.isEmpty(visitChannels) && !visit.parentId
                        ?
                        <SelectFromItemsField
                            items={visitChannels}
                            label={t("Visit channel")}
                            value={visit.visitChannelId}
                            required={visit.guest && !visit.visitChannelId}
                            error={visit.guest && !visit.visitChannelId}
                            onChange={this.handleChange('visitChannelId')}
                            nullable
                            fullWidth
                            readOnly={!editing}
                        />
                        : null
                }
                {
                    hasRole('ROLE_SCHEDULE_VISIT_CHANGE_STATUS')
                        ?
                        <SelectFromItemsField
                            items={this.getStatuses()}
                            label={t("Visit status")}
                            value={visit.status}
                            onChange={this.handleChangeStatusVisit}
                            fullWidth
                        />
                        :
                        < ShowField
                            label = {t("Visit status")}
                            value = {t(getVisitStatusText(visit.status))}
                            />

                }
                { hasRole('ROLE_SCHEDULE_VISIT_CHANGE_CREATOR') ?
                    <AutocompleteSelectField
                        label={t("Creator")}
                        value={visit && visit.creator && visit.creator.id}
                        fullWidth
                        options={availableCreators}
                        onChange={this.handleChangeCreator}
                    />
                    :
                    <ShowField
                        label={t("Creator")}
                        value={visit && visit.creator && visit.creator.username}
                    />
                }
                <ShowField
                    label={t("Created")}
                    value={visit && visit.createdAt}
                    dateFormat={"DD.MM.YYYY HH:mm"}
                />
                <ShowField
                    label={t("Cancel reason")}
                    value={visit && visit.cancelReasonText}
                    visible={Boolean(visit && visit.cancelReasonText)}
                />
                <ShowField
                    label={t("Cancel comment")}
                    value={visit && visit.cancelComment}
                    visible={Boolean(visit && visit.cancelComment)}
                />
                <SelectFromItemsField
                    items={rooms}
                    label={t("Room")}
                    value={visit.roomId}
                    onChange={this.handleChangeRoomVisit}
                    textField="displayName"
                    fullWidth
                />
                <AnyField
                    label={t("Room comment")}
                    value={visit.roomComment}
                    onChange={this.handleChange('roomComment')}
                    fullWidth
                    readOnly={!editing}
                />
                <ShowField
                    label = {t("Guest rating")}
                    value =  { visit && visit.guestRating ? visit.guestRating : '' }
                    visible = {!!visit.guestRating}
                />
                <ShowField
                    label = {t("Guest rating original message")}
                    value =  { visit && visit.guestRatingOriginalMessage ? visit.guestRatingOriginalMessage : '' }
                    visible = {!!visit.guestRatingOriginalMessage}
                />
                { visit.status === visitStatuses.new && <OrderTimer durationSeconds={visit.fillingDuration} onTimeOver={this.handleTimerOver}/> }
            </React.Fragment>
        );
    }

    handleChangeStatusVisit = (event) => {
        const {t} = this.props;

        this.setState({
            newVisitStatus: event.target.value,
        });

        this.props.showConfirm({
            message: t('Are you sure you want to change your visit status to') + ' "' + t(getVisitStatusText(event.target.value)) + '"',
            title: t('Change of visit status'),
            onClose: this.changeStatusVisit
        });
    }

    changeStatusVisit = (ok) => {
        if(!ok) return;

        const {visit, newVisitStatus} = this.state;

        patchVisit(visit.id, {status: newVisitStatus})
            .then(response => {
                if (response.success){
                    this.visitProcessing(response.data);
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }

    getPriceNumber(visitFacility) {
        if (visitFacility.start instanceof moment) {
            let timeZone1 = visitFacility.start.clone().hours(this.TIME1).minutes(0).seconds(0);
            let timeZone2 = visitFacility.start.clone().hours(this.TIME2).minutes(0).seconds(0);

            let start = this.state.visit.start.hour() + (this.state.visit.start.minutes() && 0.1);

            if (start <= timeZone1.hour()) {
                return 1;
            } else if (start > timeZone1.hour() && start <= timeZone2.hour()) {
                return 2;
            } else if (start > timeZone2.hour()) {
                return 3;
            }
        }

        return 1;
    }

    renderVisitFacility(visitFacility, idVisitFacility) {
        const { classes, t } = this.props;
        const { facilities, masters, editing, facilitiesSelectedPrice, visit } = this.state;
        let facility = null;
        if (visitFacility.facility && facilities[facilitiesSelectedPrice]) {
            facility = facilities[facilitiesSelectedPrice].find(item => item.id === visitFacility.facility);
        }
        let price = facility && visitFacility.priceId && facility.prices ? facility.prices.find(item => item.id === visitFacility.priceId) : null;
        let lengthVisitFacility = visit.visitFacility.length;
        let idLastFacilityElement = lengthVisitFacility - 1;
        let timeEnd;

        /*
            ищем фактическую последнюю услугу
            проверяем если последняя услуга состоит в группе, то ищем самую верхнюю услугу она фактически
            будет отрисована для пользователя как последняя
         */
        if (visit.visitFacility[idLastFacilityElement].visitFacilityGroup || visit.visitFacility[idLastFacilityElement].visitFacilityGroup === 0) {
            let group = visit.visitFacility[idLastFacilityElement].visitFacilityGroup;

            while (idLastFacilityElement > 0 && visit.visitFacility[idLastFacilityElement-1].visitFacilityGroup === group) {
                 idLastFacilityElement--;
            }
        }

        if (visitFacility.visitFacilityGroup || visitFacility.visitFacilityGroup === 0) {
            timeEnd = visit.visitFacilityGroup.timeEnd[visitFacility.visitFacilityGroup];
        } else {
            timeEnd = price && visitFacility.start ? visitFacility.start.clone().add(price.time, 'hours') : null;
        }

        return (
            <Grid key={idVisitFacility} item xs={12}><Grid container spacing={2} className={classes.facilityBlock}>
                <div className={classes.controlButton}>
                    {
                        editing && idVisitFacility
                            ? <Fab color="secondary" className={classes.buttonDelFacility}
                                 onClick={this.handleVisitFacilityDel(idVisitFacility)}><ClearIcon/></Fab>
                            : null
                    }
                    {
                        editing && idVisitFacility && lengthVisitFacility !== 1
                            ? <Fab color="secondary" className={classes.buttonDelFacility}
                                 onClick={this.handleUpAction(idVisitFacility)}><KeyboardArrowUp/></Fab>
                            : null
                    }
                    {
                        editing && idVisitFacility !== idLastFacilityElement && lengthVisitFacility !== 1
                            ? <Fab color="secondary" className={classes.buttonDelFacility}
                                 onClick={this.handleDownAction(idVisitFacility)}><KeyboardArrowDown/></Fab>
                            : null
                    }
                </div>
                <Grid item xs={12}><Grid container spacing={2}>
                    <Grid item>
                        <DateTimeField
                            required
                            value={visitFacility.start}
                            label={t("Start")}
                            disabled={!editing || !!idVisitFacility || !this.state.canUpdateVisitFacility}
                            onChange={this.handleDateChange(idVisitFacility)}
                            minutesStep={15}
                        />
                    </Grid>
                    <Grid item>
                        <AnyField
                            value={ timeEnd ? timeEnd.format('DD.MM.YYYY HH:mm') : ''}
                            label={t("Ending")}
                            readOnly
                        />
                    </Grid>
                </Grid></Grid>

                {
                    visit.visitFacility.map( (itemFacility, itemId) => {

                        if (itemId === idVisitFacility || (itemFacility.visitFacilityGroup !== null && itemFacility.visitFacilityGroup === visitFacility.visitFacilityGroup)) {
                            return this.renderFacility(itemFacility, itemId);
                        } else {
                            return null;
                        }
                    })
                }
                {
                    editing ?
                    <Grid item xs={12}><Grid container spacing={2}>
                        <ActionButton
                            fullWidth={false}
                            visible={editing && this.state.canUpdateVisitFacility}
                            onClick={this.handleAddVisitFacility(idVisitFacility)}
                            noMargin
                            inGridItem12
                        >
                            <Add/>
                            {t("Add service")}
                        </ActionButton>
                    </Grid></Grid>
                    : null
                }

                <Grid item xs={12} className={classes.listHeader}><FieldLabel>{t("Masters")}</FieldLabel></Grid>
                <Grid item xs={12}><Grid container spacing={2}>
                    {Array.isArray(visitFacility.master) && visitFacility.master.length > 0 ?
                        visitFacility.master.map((itemMaster, idx) => {
                            return (
                                <Grid item key={idx}>
                                    <AnyField
                                        value={ itemMaster.id }
                                        select
                                        readOnly={!editing || !this.state.canUpdateVisitFacility}
                                        onChange={this.handleChangeMaster(idx, idVisitFacility)}
                                        style={{
                                            minWidth: 200
                                        }}
                                        className={classes.masterField}
                                    >
                                        {masters.map((item, i) => <MenuItem key={i}
                                                                            value={item.id}>{item.name}</MenuItem>)}
                                    </AnyField>
                                    <CheckboxField
                                        className={classes.masterRq}
                                        value={itemMaster.requested}
                                        label="RQ"
                                        name="requested"
                                        onChange={this.handleChangeMasterRq(idx, idVisitFacility)}
                                        margin="none"
                                        disabled={!editing || !this.state.canUpdateVisitFacility}
                                    />
                                </Grid>
                            )
                        })
                        : null
                    }
                </Grid></Grid>
                {(visitFacility && visitFacility.facilityGoods && visitFacility.facilityGoods.length) || (editing && !visitFacility.visitFacilityGroup) ?
                    <Grid item xs={12} className={classes.listHeader}><Typography variant="subtitle1">{t("Additional materials")}</Typography></Grid>
                    : null
                }
                { visitFacility && visitFacility.facilityGoods
                    ? visitFacility.facilityGoods.map(  (facilityGood, index) =>
                        this.renderFacilityGoods(facilityGood, index, idVisitFacility)
                    )
                    : null
                }
                <ActionButton
                    fullWidth={false}
                    visible={editing && !visitFacility.visitFacilityGroup}
                    onClick={this.handleAddFacilityGoods(idVisitFacility)}
                    noMargin
                    inGridItem12
                >
                    <Add/> {t("Add materials")}
                </ActionButton>
            </Grid></Grid>
        )
    }

    renderFacility(visitFacility, idVisitFacility) {
        const { facilities, editing, facilitiesSelectedPrice, equipments, visit } = this.state;
        const { classes, t } = this.props;

        let facility = null;
        if (visitFacility.facility && facilities[facilitiesSelectedPrice]) {
            facility = facilities[facilitiesSelectedPrice].find(item => item.id === visitFacility.facility);
        }
        const selectedFacilities = facilities[facilitiesSelectedPrice] ? facilities[facilitiesSelectedPrice] : [];

        //Используется ли оборудование
        let isNeedEquipmentInGroup = false;
        if (visitFacility.visitFacilityGroup >= 0) {
            isNeedEquipmentInGroup = visit.visitFacility.some(vf => vf.visitFacilityGroup === visitFacility.visitFacilityGroup && vf.equipmentId);
        }

        return (
            <Grid item xs={12} key={idVisitFacility}>
                <Grid container spacing={2} className={classes.facilityItem}>
                    <Grid item>
                        <AutocompleteSelectField
                            label={ visitFacility.render ? t("Service") : null }
                            value={ visitFacility.facility }
                            required
                            error={!visitFacility.facility}
                            onChange={ this.handleFacilityChange(idVisitFacility) }
                            readOnly={!editing || !this.state.canUpdateVisitFacility}
                            style={{
                                minWidth: 300
                            }}
                            options={selectedFacilities.map(item => ({value: item.id, label: `${item.code} - ${item.name}`}))}
                        />
                    </Grid>
                    <Grid item>
                        <AnyField
                            label={ visitFacility.render ? t("Duration") : null }
                            value={visitFacility.priceId}
                            required
                            error={!visitFacility.priceId}
                            readOnly={!editing || !this.state.canUpdateVisitFacility}
                            select
                            onChange={this.handlePriceChange(idVisitFacility)}
                            style={{
                                minWidth: 200
                            }}
                        >
                            { facility && Array.isArray(facility.prices) ? facility.prices.map((item, i) => <MenuItem key={i} value={item.id}>{formatDurationHour(t,item.time)}{item.variantId ? ` (${item.variantName})`: ''}</MenuItem>) : []}
                        </AnyField>
                    </Grid>
                    <Grid item>
                        <AnyField
                            label={ visitFacility.render ? t("Cost") : null }
                            value={visitFacility.price ? visitFacility.price : null}
                            readOnly
                            style={{
                                width: 150
                            }}
                            InputProps={{
                                endAdornment: visitFacility.priceCosmetic ?
                                    <InputAdornment position="end">+{visitFacility.priceCosmetic}</InputAdornment>
                                    : null
                            }}
                        />
                    </Grid>
                    {facility && facility.needEquipmentType ?
                        <Grid item>
                            <SelectFromItemsField
                                label={visitFacility.render ? t("Equipment") : null}
                                items={equipments.filter(equipment => equipment.type === facility.needEquipmentType)}
                                value={visitFacility.equipmentId}
                                onChange={this.handleChangeEquipment(idVisitFacility)}
                                readOnly={!editing || !this.state.canUpdateVisitFacility}
                                style={{
                                    width: 170
                                }}
                            />
                        </Grid>
                        : isNeedEquipmentInGroup ?
                            <Grid item>
                                <SelectFromItemsField
                                    label={visitFacility.render ? t("Equipment") : null}
                                    items={equipments}
                                    value={visitFacility.equipmentId}
                                    onChange={this.handleChangeEquipment(idVisitFacility)}
                                    readOnly={!editing || !this.state.canUpdateVisitFacility}
                                    style={{
                                        width: 170
                                    }}
                                />
                            </Grid>
                            : null}
                    {
                        visitFacility.equipmentId
                            ? <Fab className={classes.buttonDelFacility_additional} onClick={this.handleVisitEquipmentDel(idVisitFacility)}><ClearIcon/></Fab>
                            : null
                    }
                    {
                        visitFacility.visitFacilityGroup && editing && this.state.canUpdateVisitFacility
                            ? <Fab color="secondary" className={classes.buttonDelFacility_additional} onClick={this.handleVisitFacilityDel(idVisitFacility)}><ClearIcon/></Fab>
                            : null
                    }
                </Grid>

            </Grid>

        );
    }

    renderFacilityGoods(facilityGood, index, idVisitFacility) {
        const { editing, goodUsingPrices } = this.state;
        const { classes, t } = this.props;

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                id = "name"
                                value = { facilityGood.good }
                                label={t("Name of product")}
                                readOnly={!editing}
                                fullWidth
                                select
                                onChange={this.handleGoodChange(idVisitFacility, index, 'good')}
                            >
                                { goodUsingPrices.map((item, i) => <MenuItem key={i} value={item.good.id}>{item.good.name} / {item.good.massUnitFormatted}</MenuItem>) }
                            </AnyField>
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                id = "amount"
                                label = {t("Amount")}
                                readOnly={!editing}
                                value = { facilityGood.deltaMass }
                                fullWidth
                                onChange = {this.handleGoodChange(idVisitFacility, index, 'deltaMass')}
                                InputProps={{
                                    inputComponent: NumberFormatDecimal3,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                label = {t("Price")}
                                value = { facilityGood.price }
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        {editing ? <Fab color="secondary" className={classes.goodDel} onClick={this.handleFacilityGoodDel(index, idVisitFacility)}><ClearIcon /></Fab> : null}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    handleChangeMultiDiscountRow = (index, prop) => (event) => {
        let discounts = [...this.state.visit.multiDiscount];
        discounts[index][prop] = event.target.value;

        if (prop === 'time') {
            const filteredDiscounts = this.state.discounts.filter((d) => {
                return this.filterDiscounts(d);
            });

            if (!filteredDiscounts.find((item) => discounts[index].discountId === item.id)) {
                discounts[index].discountId = null;
            }
        }

        this.setState({
            visit: {
                ...this.state.visit,
                multiDiscount: discounts
            }
        }, this.shouldBeReCalculated);
    };

    handleAddMultiDiscountRow = () => {
        let discounts = [...this.state.visit.multiDiscount];
        discounts.push({
            time: 30,
            discountId: null,
            discountNumber: null,
        });
        this.setState({
            visit: {
                ...this.state.visit,
                multiDiscount: discounts
            }
        })
    };

    handleDeleteMultiDiscountRow = (index) => () => {
        let discounts = [...this.state.visit.multiDiscount];
        discounts.splice(index, 1);
        this.setState({
            visit: {
                ...this.state.visit,
                multiDiscount: discounts
            }
        }, this.shouldBeReCalculated);
    };

    shouldBeReCalculated = () => {
        let valid = this.state.visit.multiDiscount.filter( (item) => {
            if (item.discountId === null) {
                return true;
            }
            if (!item.discountId) {
                return false;
            }
            let discount = this.state.discounts.find((discount) => item.discountId === discount.id);
            return !discount.numberRequired || (discount.numberRequired && item.discountNumber);
        });

        if ( this.state.visit.multiDiscount.length && valid.length === this.state.visit.multiDiscount.length ) {
            this.calculationPrice();
        }
    }

    handleContinueVisit = () => {
        const { visit } = this.state;

        continueVisit(visit.id)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/orders/edit/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    fetchUnclosedVisitsCheck = () => {
        const { guest, visit } = this.state
        const visitStart = moment(visit.visitFacility[0].start);

        if (guest?.id && visitStart?.isValid()) {
            const start = visitStart.toISOString();
            const end = visitStart.toISOString();
            checkUnclosedVisits([guest.id], start, end)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            unclosedVisitsCheck: response.data
                        })
                    }
                })
        }
    }

    handleChangeRoomVisit = (event) => {
        this.setState({
            newVisitRoom: event.target.value,
        });

        this.props.showConfirm({
            message: 'Вы уверены что хотите изменить комнату у визита?',
            title: 'Сменить комнату',
            onClose: this.changeRoomVisit
        });
    }

    changeRoomVisit = (ok) => {
        if(!ok) {
            return
        }

        const {visit, newVisitRoom} = this.state;

        patchVisit(visit.id, {roomId: newVisitRoom})
            .then(response => {
                if (response.success){
                    this.setState(prev => ({
                        ...prev,
                        visit: {
                            ...prev.visit,
                            roomId: newVisitRoom
                        }
                    }))
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }
}
